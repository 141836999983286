export const projects = [
    {
        "id": 1,
        "is_featured": true,
        "project_name": "Houtsaeger",
        "types": {
            "en": ["residential"],
            "nl": ["residentieel"],
            "fr": ["résidentiel"]
        },
        "main_image": "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721125935/Vitruvi/Projects/Houtsaeger/tgvkbfmgphxcut6t1fli.jpg",
        "images": [
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721125926/Vitruvi/Projects/Houtsaeger/yuheyeyoizxuob7cojwr.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721125926/Vitruvi/Projects/Houtsaeger/qaka1kuhjouhjqqon4li.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721125926/Vitruvi/Projects/Houtsaeger/ffhsu0p2bmdtfeipiggs.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721125926/Vitruvi/Projects/Houtsaeger/zy47srcribi5yj7vqwhu.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721125926/Vitruvi/Projects/Houtsaeger/fpfxmfoawjyfyxrwimxh.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721125927/Vitruvi/Projects/Houtsaeger/kylefmcqtjhqtz9bgfvr.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721125927/Vitruvi/Projects/Houtsaeger/wlvf3zts3nqiezutiq33.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721125928/Vitruvi/Projects/Houtsaeger/ms10rwse9dkssvulgqy7.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721125935/Vitruvi/Projects/Houtsaeger/tgvkbfmgphxcut6t1fli.jpg",
        ],
        "location": "Meeuwenlaan 35, De Panne",
        "status": {
            "en": "For sale",
            "nl": "Te koop",
            "fr": "Á vendre"
        },
        "architect": "Vertongen architecten",
        "website": "www.houtsaeger.vitruvi.be",
        "programs":
            [
                {
                    "en": "9 apartments",
                    "nl": "9 appartementen",
                    "fr": "9 appartements"
                },
                {
                    "en": "9 apartments",
                    "nl": "9 appartementen",
                    "fr": "9 appartements"
                },
            ],
        "unique_aspect": {
            "en": "Close to the beach",
            "nl": "Vlakbij het strand",
            "fr": "Proche de la plage"
        },
        "description_short": {
            "en": "Project 'Houtsaeger' offers 9 modern apartments with spacious terraces and plenty of natural light in a central yet quiet location in De Panne, close to the beach and various amenities.",
            "nl": "Project 'Houtsaeger' biedt 9 moderne appartementen met ruime terrassen en veel natuurlijk licht op een centrale maar rustige locatie in De Panne, dichtbij het strand en diverse voorzieningen.",
            "fr": "Le projet 'Houtsaeger' propose 9 appartements modernes avec de grandes terrasses et beaucoup de lumière naturelle dans un emplacement central mais calme à La Panne, proche de la plage et de diverses commodités."
        },
        "description": {
            "en": "Project 'Houtsaeger' is located at the corner of Meeuwenlaan and J. Demolderlaan in De Panne, near the characterful Dumontwijk and authentic Houtsaegerwijk, a central yet quiet location. The beach, shops, restaurants, the market, various nature reserves, etc. are all within walking distance. The project includes 9 high-quality apartments with 2 to 3 bedrooms, spread over 6 floors. The building exudes a modern and sleek style.<br><br> Each apartment is finished with contemporary materials and has a spacious and sunny terrace. Large windows in the living area provide plenty of natural light and a spacious feeling. There are also 2 garage boxes with direct access through the garage doors, as well as a bicycle storage room on the ground floor and 9 private cellar storage rooms.",
            "nl": "Project 'Houtsaeger' is gelegen op de hoek van de Meeuwenlaan en J. Demolderlaan in De Panne, vlakbij de karaktervolle Dumontwijk en authentieke Houtsaegerwijk, een centrale en toch rustige locatie. Het strand, winkels, restaurants, de markt, verschillende natuurgebieden, etc. zijn allemaal op wandelafstand. Het project omvat 9 kwalitatieve appartementen met 2 tot 3 slaapkamers, verdeeld over 6 bouwlagen. Het gebouw straalt een moderne en strakke stijl uit.<br><br> Elk appartement wordt afgewerkt met hedendaagse materialen en beschikt over een ruim en zonnig terras. Grote raampartijen in de leefruimte zorgen voor veel natuurlijk licht en een ruimtelijk gevoel. Er zijn ook 2 garageboxen met directe toegang via de garagepoorten, evenals een fietsenberging op de gelijkvloerse verdieping en 9 privatieve kelderbergingen.",
            "fr": "Le projet 'Houtsaeger' est situé à l'angle de la Meeuwenlaan et de la J. Demolderlaan à La Panne, près des quartiers pittoresques de Dumontwijk et Houtsaegerwijk, un emplacement central mais calme. La plage, les magasins, les restaurants, le marché, diverses réserves naturelles, etc. sont tous accessibles à pied. Le projet comprend 9 appartements de qualité avec 2 à 3 chambres, répartis sur 6 étages. Le bâtiment dégage un style moderne et épuré.<br><br> Chaque appartement est fini avec des matériaux contemporains et dispose d'une terrasse spacieuse et ensoleillée. De grandes fenêtres dans le salon offrent beaucoup de lumière naturelle et une sensation d'espace. Il y a aussi 2 boxes de garage avec un accès direct par les portes de garage, ainsi qu'un local à vélos au rez-de-chaussée et 9 caves privées."
        },
    },
    {
        "id": 2,
        "is_featured": false,
        "project_name": "De Gouden Lanteern",
        "types": {
            "en": ["residential"],
            "nl": ["residentieel"],
            "fr": ["résidentiel"]
        },
        "main_image": "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126437/Vitruvi/Projects/Gouden%20lantaarne/hocxeq2sovyydanv0lwb.jpg",
        "images": [
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126427/Vitruvi/Projects/Gouden%20lantaarne/enjyvo19qtwozffembqm.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126427/Vitruvi/Projects/Gouden%20lantaarne/oxsldn5jisimkglmgtoz.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126427/Vitruvi/Projects/Gouden%20lantaarne/zsmuku41rl9cnyoslkxn.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126428/Vitruvi/Projects/Gouden%20lantaarne/d2vdi7hozmpdqpfbx4rc.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126428/Vitruvi/Projects/Gouden%20lantaarne/i5fchquavxc3yrp67ouv.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126428/Vitruvi/Projects/Gouden%20lantaarne/kdiwnywbqredoayjkm7w.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126428/Vitruvi/Projects/Gouden%20lantaarne/kup0ac045orzynndtcsv.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126428/Vitruvi/Projects/Gouden%20lantaarne/nm7cmwy0glyudzs0ic5h.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126429/Vitruvi/Projects/Gouden%20lantaarne/qhgtmrnxeiurzb0gvvln.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126429/Vitruvi/Projects/Gouden%20lantaarne/r9wp50rrxbfbv8pfwwej.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126429/Vitruvi/Projects/Gouden%20lantaarne/v8goov6d7zepegm9gw9x.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126430/Vitruvi/Projects/Gouden%20lantaarne/jwar5gveizmnqlwgwofn.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126430/Vitruvi/Projects/Gouden%20lantaarne/krsvyvdzupeddjbc7xa2.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126430/Vitruvi/Projects/Gouden%20lantaarne/nxhgwv3musgetnaj1zu3.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126430/Vitruvi/Projects/Gouden%20lantaarne/xjbehsorlkrl4wm7xmzs.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126431/Vitruvi/Projects/Gouden%20lantaarne/liol9biros9qmvwmwz3s.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126431/Vitruvi/Projects/Gouden%20lantaarne/pkjkgjpdo7vdghmd5yun.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126432/Vitruvi/Projects/Gouden%20lantaarne/vnjand8bvu8hlpozw9ma.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126432/Vitruvi/Projects/Gouden%20lantaarne/ytcctkygtg4etae19ofm.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721126437/Vitruvi/Projects/Gouden%20lantaarne/hocxeq2sovyydanv0lwb.jpg",
        ],
        "location": "Jan Persijnstraat 6, Kortrijk",
        "status": {
            "en": "Last apartment",
            "nl": "Laatste appartement",
            "fr": "Dernier appartement"
        },
        "architect": "GC Architecten",
        "website": "www.degoudenlanteern.be",
        "programs":
            [
                {
                    "en": "9 apartments",
                    "nl": "9 appartementen",
                    "fr": "9 appartements"
                }
            ],
        "unique_aspect": {
            "en": "Iconic building",
            "nl": "Iconisch gebouw",
            "fr": "Bâtiment emblématique"
        },
        "description_short": {
            "en": "<p>The project consists of 9 new-build apartments with a shared city garden, a city house with its own garden, and parking spaces.<br><br>The project is located in the heart of Kortrijk, about 50m from the main square and close to all services and amenities.</p>",
            "nl": "<p>Het project bestaat uit 9 nieuwbouwappartementen met een gemeenschappelijke stadstuin, een stadswoning met eigen tuin en autostaanplaatsen.<br><br>Het project is gelegen in het hart van Kortrijk, op ongeveer 50m van de grote markt en dichtbij alle diensten en voorzieningen.</p>",
            "fr": "<p>Le projet se compose de 9 appartements neufs avec un jardin commun, une maison de ville avec son propre jardin et des places de stationnement.<br><br>Le projet est situé au cœur de Courtrai, à environ 50 m de la grande place et à proximité de tous les services et commodités.</p>"
        },
        "description": {
            "en": "<p>After ten years of vacancy, the curtain falls on the old cinema hall De Gouden Lanteern, later Ciné Orly and culture temple Limelight, at Jan Persijnstraat 6 in Kortrijk. The iconic facade will be preserved: it will be restored to its former glory in the new residential project 'De Gouden Lanteern'.</p>",
            "nl": "<p>Na tien jaar leegstand valt het doek over de oude cinemazaal De Gouden Lanteern, de latere Ciné Orly en cultuurtempel Limelight, in de Jan Persijnstraat 6 in Kortrijk. De aanblik van de iconische gevel zal behouden blijven: die wordt in ere hersteld in het nieuw woonproject 'De Gouden Lanteern'.</p>",
            "fr": "<p>Après dix ans de vacance, le rideau tombe sur l'ancienne salle de cinéma De Gouden Lanteern, plus tard Ciné Orly et temple de la culture Limelight, au Jan Persijnstraat 6 à Courtrai. La façade emblématique sera préservée : elle sera restaurée dans toute sa splendeur dans le nouveau projet résidentiel 'De Gouden Lanteern'.</p>"
        }
    },
    {
        "id": 3,
        "is_featured": false,
        "project_name": "De Spitter",
        "types": {
            "en": ["students housing"],
            "nl": ["studenten huisvesting"],
            "fr": ["logement étudiants"]
        },
        "main_image": "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127045/Vitruvi/Projects/De%20Spitter/e6udyaidzxlbeomgrusb.jpg",

        "images": [
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127036/Vitruvi/Projects/De%20Spitter/uk2ki1w24u2ldqsyvbmu.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127036/Vitruvi/Projects/De%20Spitter/fl6w3doct8wunpea9jkb.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127036/Vitruvi/Projects/De%20Spitter/bl8q4jccrh3oyyxnaaee.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127037/Vitruvi/Projects/De%20Spitter/adxjx4bunojlgefn90wf.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127044/Vitruvi/Projects/De%20Spitter/yklwh7ahfjgc3qow2h3e.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127045/Vitruvi/Projects/De%20Spitter/e6udyaidzxlbeomgrusb.jpg",
        ],
        "location": "Zwevegemsestraat 49, Kortrijk",
        "status": {
            "en": "Delivered",
            "nl": "Opgeleverd",
            "fr": "Livré"
        },
        "architect": "A154 Architecten",
        "website": "www.spitter.be",
        "programs":
            [
                {
                    "en": "9 apartments",
                    "nl": "9 appartementen",
                    "fr": "9 appartements"
                }
            ],
        "unique_aspect": {
            "en": "For students",
            "nl": "Voor studenten",
            "fr": "Pour les étudiants"
        },
        "description_short": {
            "en": "",
            "nl": "",
            "fr": ""
        },
        "description": {
            "en": "",
            "nl": "",
            "fr": ""
        },
    },
    {
        "id": 4,
        "is_featured": false,
        "project_name": "Zandwegemolen",
        "types": {
            "en": ["offices"],
            "nl": ["kantoren"],
            "fr": ["bureaux"]
        },
        "main_image": "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127250/Vitruvi/Projects/Zandwegemolen/mr2krx06xiw7a3uwtlx3.png",
        "images": [
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127249/Vitruvi/Projects/Zandwegemolen/bx5osu3jbgshq0qr03ts.png",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127250/Vitruvi/Projects/Zandwegemolen/mr2krx06xiw7a3uwtlx3.png",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127251/Vitruvi/Projects/Zandwegemolen/nefafaiipsseitsafwjv.png",
        ],
        "location": "Oude Oostendsesteenweg 91, Brugge",
        "status": {
            "en": "Under construction",
            "nl": "In aanbouw",
            "fr": "En construction"
        },
        "architect": "Salens Architecten",
        "website": "",
        "programs":
            [
                {
                    "en": "9 apartments",
                    "nl": "9 appartementen",
                    "fr": "9 appartements"
                }
            ],
        "unique_aspect": {
            "en": "By the mill",
            "nl": "Aan de molen",
            "fr": "À côté du moulin"
        },
        "description_short": {
            "en": "",
            "nl": "",
            "fr": ""
        },
        "description": {
            "en": "<p>In 1860, Louis Matthys built a stone grain and oil windmill next to the Zandweg Inn. After many years of service by several owners, the Zandwegemolen underwent significant renovations in 1932 during the economic crisis. On Thursday, September 7, 1944, a strong wind brought down the blades.<br><br>To continue milling after this accident, just after liberation, Jules Caene ordered the mill to be fitted with an emergency cap and a diesel engine on the ground floor, which continued to be used until the early 1960s.<br><br>From 1969, the mill became a bar, and a restaurant and banquet hall were built around it. On April 27, 1995, the Commeyne family purchased the mill and the hospitality business. They conducted a thorough, operational restoration of the mill from 2003 to 2005.<br><br>In recent years, the operation of the restaurant and banquet hall has ceased, and Vitruvi has redeveloped it into an office campus.</p>",
            "nl": "In 1860 richtte Louis Matthys een stenen graan- en oliewindmolen op naast de Zandwegherberg. Na vele jaren van dienst door meerdere eigenaars wordt de Zandwegemolen in 1932, in volle crisistijd, grondig opgeknapt. Op donderdag 7 september 1944 werd door een zeer hevige wind de wieken neergehaald.<br><br>Om na dit ongeval, net na de bevrijding, toch verder te kunnen malen werd door Jules Caene opdracht gegeven om de molen van een noodkap te voorzien en een dieselmotor op te stellen op het gelijkvloers waarmee verder werd gemalen tot het begin van de jaren zestig.<br><br>Vanaf 1969 was de molen een bar en werd er rond een restaurant en feestzaal gebouwd. Op 27 april 1995 kocht de familie Commeyne de molen en horecazaak aan. Zij lieten in 2003-2005 een zeer grondige maalvaardige restauratie uitvoeren aan de molen.<br><br>Sinds enkele jaren is de uitbating van het restaurant en de feestzaal gestopt. En heeft Vitruvi het herontwikkeld naar een kantorencampus.",
            "fr": "<p>En 1860, Louis Matthys a construit un moulin à vent en pierre pour le grain et l'huile à côté de l'auberge Zandweg. Après de nombreuses années de service par plusieurs propriétaires, le moulin de Zandwege a été rénové en profondeur en 1932, en pleine crise. Le jeudi 7 septembre 1944, une forte rafale de vent a abattu les ailes.<br><br>Pour continuer à moudre après cet accident, juste après la libération, Jules Caene a ordonné de doter le moulin d'un capuchon d'urgence et d'installer un moteur diesel au rez-de-chaussée, qui a continué à fonctionner jusqu'au début des années 1960.<br><br>À partir de 1969, le moulin est devenu un bar, et un restaurant et une salle de banquet ont été construits autour. Le 27 avril 1995, la famille Commeyne a acheté le moulin et l'entreprise de restauration. Ils ont effectué une restauration opérationnelle complète du moulin de 2003 à 2005.<br><br>Ces dernières années, l'exploitation du restaurant et de la salle de banquet a cessé, et Vitruvi l'a redéveloppé en campus de bureaux.</p>"
        },
    },
    {
        "id": 5,
        "is_featured": false,
        "project_name": "Hadlow",
        "types": {
            "en": ["residential", "commercial"],
            "nl": ["residentieel", "commercieel"],
            "fr": ["résidentiel", "commercial"]
        },
        "main_image": "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127435/Vitruvi/Projects/Hadlow/mhrjhui4cbtd72xiudgb.jpg",
        "images": [
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127434/Vitruvi/Projects/Hadlow/h98nzlmvamceejxwwlj0.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127435/Vitruvi/Projects/Hadlow/a02wnquwgd1ceuvjqui4.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127435/Vitruvi/Projects/Hadlow/y0smuxjujfu1p0tuzybe.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127435/Vitruvi/Projects/Hadlow/mhrjhui4cbtd72xiudgb.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127435/Vitruvi/Projects/Hadlow/r1yixrreyhudmvhy00fb.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127435/Vitruvi/Projects/Hadlow/qadjd7a1hrhxopgb59ji.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127435/Vitruvi/Projects/Hadlow/hxw2amn46cpm0gzfkna0.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127436/Vitruvi/Projects/Hadlow/tf8wc4dcxdcm2nqzhoup.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127437/Vitruvi/Projects/Hadlow/angnyluqzel0r5ivvkwt.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127437/Vitruvi/Projects/Hadlow/ki536ril6vvhajkzdt2z.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127437/Vitruvi/Projects/Hadlow/ligv2ydneibjpjxz0v1v.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127437/Vitruvi/Projects/Hadlow/snfsxxjchkagmaucrzmm.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127437/Vitruvi/Projects/Hadlow/i1zv6yyung3jawqkm6wc.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127437/Vitruvi/Projects/Hadlow/gvnlpjpbou72tmxe4zqd.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127438/Vitruvi/Projects/Hadlow/s9wwvjq7jhbg68x1vvk7.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127438/Vitruvi/Projects/Hadlow/tvw9rhzvucdphttxlcbd.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127439/Vitruvi/Projects/Hadlow/ta6udeywr0vk1qlzmhtc.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127439/Vitruvi/Projects/Hadlow/rauzdzyuqncscqybph3c.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127439/Vitruvi/Projects/Hadlow/uktbsinm1obt2aw9btxz.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127439/Vitruvi/Projects/Hadlow/xo4pvjgjmhngtdij0yot.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127439/Vitruvi/Projects/Hadlow/mg2tpfbvfa6fxyrcztup.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127439/Vitruvi/Projects/Hadlow/wkf9wnxbji8zwpkw3kw0.jpg",
            "https://res.cloudinary.com/ddzdhm0u6/image/upload/v1721127440/Vitruvi/Projects/Hadlow/ikpime6uytl8igg3xhkn.jpg",
        ],
        "location": "Rijselsestraat 27, Kortrijk",
        "status": {
            "en": "Sold out",
            "nl": "Uitverkocht",
            "fr": "Épuisé"
        },
        "architect": "A154",
        "website": "",
        "programs":
            [
                {
                    "en": "9 apartments",
                    "nl": "9 appartementen",
                    "fr": "9 appartements"
                },
            ],
        "unique_aspect": {
            "en": "In the heart of Kortrijk",
            "nl": "In hartje Kortrijk",
            "fr": "Au cœur de Courtrai"
        },
        "description_short": {
            "en": "'Hadlow Kortrijk' is a renovation project consisting of 9 apartments and a commercial space. The corner building is located at Sint-Michielsplein, just a stone's throw from the main square and close to all the services and amenities of the city of Kortrijk.",
            "nl": "'Hadlow Kortrijk' is een renovatieproject bestaande uit 9 appartementen en een commerciële ruimte. Het hoekpand is gelegen aan het Sint-Michielsplein op een steenworp van de grote markt en dicht bij alle diensten en voorzieningen van de stad Kortrijk.",
            "fr": "'Hadlow Kortrijk' est un projet de rénovation comprenant 9 appartements et un espace commercial. Le bâtiment d'angle est situé sur la place Saint-Michel, à deux pas de la grande place et à proximité de tous les services et commodités de la ville de Courtrai."
        },
        "description": {
            "en": "",
            "nl": "",
            "fr": ""
        },
    },
]