export const news = [
    {
        "id": 1,
        "is_featured": false,
        "date": '01/05/2020',
        "by": "Vitruvi",
        "content": {
            "title": {
                "en": "Launch of 'Hadlow Kortrijk'",
                "nl": "Lancering 'Hadlow Kortrijk'",
                "fr": "Lancement de 'Hadlow Kortrijk'"
            },
            "main_paragraph": {
                "en": "Project 'Hadlow Kortrijk' is located at the corner of Rijselsestraat and Jan Persijnstraat, in the heart of Kortrijk, about 50 meters from the Grote Markt and close to all services and amenities of the city of Kortrijk. The building is located opposite the Sint-Michielsplein, for which the city administration has plans to develop it into a green square.",
                "nl": "Project 'Hadlow Kortrijk' is gelegen op de hoek van de Rijselsestraat met de Jan Persijnstraat, in hartje Kortrijk op ongeveer 50 meter van de Grote Markt en dicht bij alle diensten en voorzieningen van de stad Kortrijk. Het gebouw is gelegen rechtover het Sint-Michielsplein, waarvoor plannen zijn binnen het stadsbestuur om dit aan te leggen tot een groenplein.",
                "fr": "Le projet 'Hadlow Kortrijk' est situé à l'angle de la Rijselsestraat et de la Jan Persijnstraat, au cœur de Courtrai, à environ 50 mètres du Grote Markt et à proximité de tous les services et commodités de la ville de Courtrai. Le bâtiment est situé en face du Sint-Michielsplein, pour lequel l'administration de la ville prévoit de le développer en une place verte."
            },
        },
        "main_image": "news1.jpg",
    },
    {
        "id": 2,
        "is_featured": false,
        "date": '01/06/2021',
        "by": "Vitruvi",
        "content": {
            "title": {
                "en": "Launch of 'De Gouden Lanteern'",
                "nl": "Lancering 'De Gouden Lanteern'",
                "fr": "Lancement de 'De Gouden Lanteern'"
            },
            "main_paragraph": {
                "en": "After ten years of vacancy, the curtain falls on the old cinema hall De Gouden Lanteern, later Ciné Orly and culture temple Limelight, at Jan Persijnstraat 6 in Kortrijk. The iconic facade will be preserved and restored in the new residential project 'De Gouden Lanteern'.",
                "nl": "Na tien jaar leegstand valt het doek over de oude cinemazaal De Gouden Lanteern, de latere Ciné Orly en cultuurtempel Limelight, in de Jan Persijnstraat 6 in Kortrijk. De aanblik van de iconische gevel zal echter behouden blijven die wordt in ere hersteld in het nieuw woonproject 'De Gouden Lanteern'.",
                "fr": "Après dix ans de vacance, le rideau tombe sur l'ancienne salle de cinéma De Gouden Lanteern, plus tard Ciné Orly et temple de la culture Limelight, au Jan Persijnstraat 6 à Courtrai. La façade emblématique sera conservée et restaurée dans le nouveau projet résidentiel 'De Gouden Lanteern'."
            },
        },
        "main_image": "news2.jpg",
    },
    {
        "id": 3,
        "is_featured": false,
        "date": '30/12/2021',
        "by": "Vitruvi",
        "content": {
            "title": {
                "en": "'Dare to Dream' campaign with Dakar Rally driver Mathieu Liebaert",
                "nl": "'Dare to Dream' campagne met Dakar Rally rijder Mathieu Liebaert",
                "fr": "Campagne 'Dare to Dream' avec le pilote du Dakar Mathieu Liebaert"
            },
            "main_paragraph": {
                "en": "At Vitruvi, we want everyone to pursue their dreams, no matter how big or extreme they are. Whether it's playing champion in your sport or driving the toughest rally in the world, we believe that with a good dose of ambition, perseverance, and striving for perfection, anything is possible. With our 'Dare To Dream' campaign, we want to support and follow people in achieving their dreams.",
                "nl": "Bij Vitruvi willen we dat iedereen zijn dromen najaagt, hoe groot of extreem ze ook zijn. Of het nu kampioen spelen is in uw sport of de zwaarste rally rijden van de wereld, wij geloven dat met een goeie brok ambitie, volharding en het streven naar perfectie alles mogelijk is. Met onze 'Dare To Dream' campagne willen wij mensen ondersteunen en volgen in het bereiken van hun dromen.",
                "fr": "Chez Vitruvi, nous voulons que tout le monde poursuive ses rêves, aussi grands ou extrêmes soient-ils. Que ce soit jouer le champion dans votre sport ou conduire le rallye le plus dur du monde, nous croyons qu'avec une bonne dose d'ambition, de persévérance et de recherche de perfection, tout est possible. Avec notre campagne 'Dare To Dream', nous voulons soutenir et suivre les gens dans la réalisation de leurs rêves."
            },
        },
        "main_image": "news3.jpg",
    },
    {
        "id": 4,
        "is_featured": false,
        "date": '01/09/2022',
        "by": "Vitruvi",
        "content": {
            "title": {
                "en": "Dare to Invest - Buy now or wait",
                "nl": "Dare to Invest - Nu kopen of wachten",
                "fr": "Dare to Invest - Acheter maintenant ou attendre"
            },
            "main_paragraph": {
                "en": "In recent months, there has been uncertainty in the real estate market. Will construction prices fall, stabilize, or continue to rise? What is the impact of this on land prices and the affordability of real estate? How can investors, future buyers, and tenants best anticipate this?",
                "nl": "De laatste maanden heerst er onzekerheid op de vastgoedmarkt. Gaan de bouwprijzen zakken, stabiliseren of nog stijgen? Wat is het effect hiervan op de grondprijzen en op de betaalbaarheid van vastgoed. Hoe kunnen beleggers, toekomstige kopers, huurders hierop het beste anticiperen?",
                "fr": "Ces derniers mois, il y a eu de l'incertitude sur le marché immobilier. Les prix de la construction vont-ils baisser, se stabiliser ou continuer à augmenter ? Quel est l'impact de cela sur les prix des terrains et sur l'abordabilité de l'immobilier ? Comment les investisseurs, les futurs acheteurs et les locataires peuvent-ils mieux anticiper cela ?"
            },
        },
        "main_image": "",
    },
    {
        "id": 5,
        "is_featured": false,
        "date": '01/03/2023',
        "by": "Vitruvi",
        "content": {
            "title": {
                "en": "Vitruvi collaborates with Capitall and Diggit Studentlife for Spitter",
                "nl": "Vitruvi gaat samenwerking aan met Capitall en Diggit Studentlife voor Spitter",
                "fr": "Vitruvi collabore avec Capitall et Diggit Studentlife pour Spitter"
            },
            "main_paragraph": {
                "en": "We are pleased to announce that we are entering into a partnership for our student housing project Spitter49 in Kortrijk. Capitall, a specialist in student investment real estate, will be responsible for the commercialization and investment guidance of the project. As a young company in the sector, Capitall is constantly seeking new opportunities to improve the quality of student life and thereby optimize and professionalize student housing. This collaboration fits perfectly within this vision.",
                "nl": "Wij zijn verheugd om aan te kondigen dat we een samenwerking aangaan voor ons studentenhuisvestingproject Spitter49 te Kortrijk. Capitall, een specialist in studenten investeringsvastgoed, zal instaan voor de commercialisatie en de investeringsbegeleiding van het project. Als jong bedrijf in de sector is Capitall voortdurend op zoek naar nieuwe kansen om de kwaliteit van het studentenleven te verbeteren en hiermee ook de huisvesting van studenten te optimaliseren en professionaliseren. Deze samenwerking past perfect binnen deze visie.",
                "fr": "Nous sommes heureux d'annoncer que nous allons entrer en partenariat pour notre projet de logement étudiant Spitter49 à Courtrai. Capitall, un spécialiste de l'immobilier d'investissement étudiant, sera responsable de la commercialisation et de l'accompagnement en investissement du projet. En tant que jeune entreprise du secteur, Capitall recherche constamment de nouvelles opportunités pour améliorer la qualité de vie des étudiants et ainsi optimiser et professionnaliser les logements étudiants. Cette collaboration s'inscrit parfaitement dans cette vision."
            }
        },
        "main_image": "news5.jpg",
    },
]
