import React, { useEffect, useRef } from 'react'
import './Map.css'

const Map = ({ address, zoom }) => {
  const mapRef = useRef(null)

  useEffect(() => {
    const initMap = (position) => {
      const map = new window.google.maps.Map(mapRef.current, {
        center: position,
        zoom: zoom,
        disableDefaultUI: true,
        zoomControl: true,
        styles: [
          {
            elementType: 'geometry',
            stylers: [{ color: '#f5f5f5' }],
          },
          {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [{ color: '#616161' }],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#f5f5f5' }],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#bdbdbd' }],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: '#eeeeee' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#757575' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#e5e5e5' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9e9e9e' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#ffffff' }],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#757575' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#dadada' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#616161' }],
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9e9e9e' }],
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [{ color: '#e5e5e5' }],
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [{ color: '#eeeeee' }],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#c9c9c9' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9e9e9e' }],
          },
        ],
      })

      const markerIcon = {
        path: 'M256 0C156.698 0 76.8 79.867 76.8 177.778 76.8 312.273 256 512 256 512s179.2-199.727 179.2-334.222C435.2 79.867 355.302 0 256 0zm0 256c-43.509 0-79.104-35.595-79.104-79.104S212.491 97.792 256 97.792s79.104 35.595 79.104 79.104S299.509 256 256 256z',
        fillColor: '#5F5F5F',
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 0.1, // Adjust the scale as needed
        anchor: new window.google.maps.Point(256, 512), // Adjust the anchor point to match the marker's base
      }

      new window.google.maps.Marker({
        position: position,
        map,
        icon: markerIcon,
        title: 'Location',
      })
    }

    const geocodeAddress = async (address) => {
      const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`)
      const data = await response.json()
      if (data.results && data.results[0]) {
        const position = data.results[0].geometry.location
        initMap(position)
      } else {
        console.error('Geocoding failed: ', data)
      }
    }

    const loadScript = (url) => {
      const script = document.createElement('script')
      script.src = url
      script.async = true
      script.defer = true
      document.head.appendChild(script)
      script.onload = () => {
        if (window.google) {
          geocodeAddress(address)
        }
      }
    }

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

    if (apiKey) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}`)
    } else {
      console.error('Google Maps API key is missing')
    }
  }, [address])

  return <div id="map" ref={mapRef} style={{ height: '400px', width: '100%' }}></div>
}

Map.defaultProps = {
  zoom: 16,
}

export default Map
