import React from 'react'
import './NewsItem.css'
import VideoPlayer from '../VideoPlayer'
import { news3Content } from '../../assets/content/content'
import { getLanguage } from '../helpers/helpers'

function News3() {
  const selectedLang = getLanguage()
  const content = news3Content[selectedLang]

  return (
    <div className='news_item_body'>
      <img className='img_full_width' src={require(`../../assets/images/news/news3.1.jpg`)} alt='News 3' />

      <p className='paragraph'>
        {content.paragraph1}
      </p>

      <VideoPlayer url={'https://www.youtube.com/watch?v=SZxMTAiGO14'} />

      <p className='paragraph'>
        {content.paragraph2}
      </p>

      <p className='paragraph'>
        {content.paragraph3}
      </p>

      <VideoPlayer url={'https://youtu.be/rvpQP2Gy7_Q'} />

      <p className='paragraph'>
        {content.paragraph4}
      </p>

      <p className='paragraph'>
        {content.paragraph5}
      </p>

    </div>
  )
}

export default News3
