import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HiOutlinePhone } from "react-icons/hi2"
import { CiMail } from "react-icons/ci"
import { TfiLocationPin } from "react-icons/tfi"
import './Footer.css'
import { footerContent } from '../assets/content/content'
import { getLanguage } from './helpers/helpers.js'

function Footer() {
  const selectedLang = getLanguage()
  const content = footerContent[selectedLang]
  const navigate = useNavigate()

  return (
    <div className='footer' id='contact'>
      <div className='contact__details'>
        <div className='contact__detail' onClick={() => window.location.href = 'tel:+3256444554'}>
          <HiOutlinePhone />
          <p className='mini_title'>{content.bel}</p>
          <p>+32(0)56 44 45 54</p>
          <div className='button'>{content.call}</div>
        </div>
        <div className='contact__detail' onClick={() => window.location.href = 'mailto:info@vitruvi.be'}>
          <CiMail />
          <p className='mini_title'>{content.mail}</p>
          <p>info@vitruvi.be</p>
          <div className='button'>{content.emailButton}</div>
        </div>
        <div className='contact__detail' onClick={() => window.open('https://www.google.com/maps?q=Rijselsestraat+33,+8500+Kortrijk')}>
          <TfiLocationPin />
          <p className='mini_title'>{content.bezoek}</p>
          <p>Rijselsestraat 33 <br /> 8500 Kortrijk</p>
          <div className='button'>{content.visit}</div>
        </div>
      </div>

      <img src={require('../assets/logo/logo_vitruvi_frontpage.png')} className='footer_logo' alt='Vitruvi logo' />

      <div className='disclaimer' onClick={() => navigate('/legal')}>
        <div>© Vitruvi 2023</div>
        <div>|</div>
        <div>{content.disclaimer}</div>
      </div>
    </div>
  )
}

export default Footer
