import React, { useEffect, useState } from 'react'
import './ContactMenu.css'
import { useParams } from "react-router-dom"
import { HiOutlinePhone } from "react-icons/hi2"
import { CiMail } from "react-icons/ci"
import { TfiLocationPin } from "react-icons/tfi"
import { contactFormContent } from '../assets/content/content.js'
import { getLanguage } from './helpers/helpers.js'
import ContactForm from './ContactForm.js'

function ContactMenu({ id, tag }) {

  const selectedLang = getLanguage()
  const content = contactFormContent[selectedLang]

  return (
    <div className='contact_form__container' id={id}>
      <h1>{content.title}</h1>
      <div className='contact__details'>
        <div className='contact__detail' onClick={() => window.location.href = 'tel:+3256444554'}>
          <HiOutlinePhone />
          <p className='mini_title'>{content.bel}</p>
          <p>+32(0)56 44 45 54</p>
          <div className='button'>{content.call}</div>
        </div>
        <div className='contact__detail' onClick={() => window.location.href = 'mailto:info@vitruvi.be'}>
          <CiMail />
          <p className='mini_title'>{content.mail}</p>
          <p>info@vitruvi.be</p>
          <div className='button'>{content.emailButton}</div>
        </div>
        <div className='contact__detail' onClick={() => window.open('https://www.google.com/maps?q=Rijselsestraat+33,+8500+Kortrijk')}>
          <TfiLocationPin />
          <p className='mini_title'>{content.bezoek}</p>
          <p>Rijselsestraat 33 <br /> 8500 Kortrijk</p>
          <div className='button'>{content.visit}</div>
        </div>
      </div>

      <ContactForm tag={tag} />
    </div>
  )
}

export default ContactMenu
