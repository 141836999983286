import { React, useState, useEffect, useRef } from 'react'
import './LegalPage.css'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import { getLanguage } from '../components/helpers/helpers'
import { legalPageContent } from '../assets/content/content'

function LegalPage() {
  const [selectedSubMenu, setSelectedSubMenu] = useState("legal")
  const selectedLang = getLanguage()
  const content = legalPageContent[selectedLang]

  const pageTitleRef = useRef(null)
  const highlightTimer = useRef(null)
  const pageTitleWords = content.pageTitle.split(' ')

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0)

    const pageTitle = pageTitleRef.current
    const highlight = pageTitle.querySelector('.highlight')

    highlightTimer.current = setTimeout(() => {
      highlight.classList.add('animated')
    }, 750)

    return () => {
      if (highlightTimer.current) {
        clearTimeout(highlightTimer.current)
      }
    }
  }, [])
  return (
    <div className='content_page' id={`legal-page`}>

      <Menu
        isLogoVisible={true}
        menuLogo={require('../assets/logo/logo_vitruvi_transparant_color.png')}
        includeGoBack={true}
      />

      <div className='content_page_body'>
        <h1 className='page_title' ref={pageTitleRef}>
          {pageTitleWords.slice(0, -1).map((word, index) => (
            <div key={index} className='page_title__word visible'>{word}</div>
          ))}
          <div className='page_title__word visible highlight'>{pageTitleWords[pageTitleWords.length - 1]}</div>
        </h1>

        <div className='content_page__content'>
          <div className='sub_nav'>
            <div onClick={() => setSelectedSubMenu("legal")}>{content.legal.subTitle}</div>
            <div onClick={() => setSelectedSubMenu("policy")}>{content.policy.subTitle}</div>
            <div onClick={() => setSelectedSubMenu("cookies")}>{content.cookies.subTitle}</div>
          </div>

          {
            selectedSubMenu === "legal" && (
              <>
                <div className='sub_title'>{content.legal.subTitle}</div>
                {content.legal.paragraphs.map((paragraph, index) => (
                  <div key={index} className='paragraph'>{paragraph}</div>
                ))}
              </>
            )
          }

          {
            selectedSubMenu === "policy" && (
              <>
                <div className='sub_title'>{content.policy.subTitle}</div>
                {content.policy.paragraphs.map((paragraph, index) => (
                  <div key={index} className='paragraph'>{paragraph}</div>
                ))}
              </>
            )
          }

          {
            selectedSubMenu === "cookies" && (
              <>
                <div className='sub_title'>{content.cookies.subTitle}</div>
                {content.cookies.paragraphs.map((paragraph, index) => (
                  <div key={index} className='paragraph'>{paragraph}</div>
                ))}
              </>
            )
          }

        </div>
      </div>

      <Footer />
    </div>
  )
}

export default LegalPage
