import React, { useState, useEffect } from 'react'
import './CookieAccepter.css'
import { getLanguage } from '../components/helpers/helpers'
import { cookieAccepterContent } from '../assets/content/content'

function CookieAccepter() {
  const [isVisible, setIsVisible] = useState(false)

  const selectedLang = getLanguage()
  const content = cookieAccepterContent[selectedLang]

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccepted')
    if (!cookieAccepted) {
      setIsVisible(true)
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', 'true')
    setIsVisible(false)
  }

  if (!isVisible) {
    return null
  }

  return (
    <div className="cookie-accepter">
      <div className="cookie-message">
        <p><strong>{content.title}</strong></p>
        <p>{content.message} <a href="/legal">{content.linkText}</a></p>
      </div>
      <button onClick={handleAccept} className="cookie-button">{content.acceptButton}</button>
    </div>
  )
}

export default CookieAccepter
