import React from 'react'
import './NewsItem.css'
import { getLanguage } from '../helpers/helpers'
import { news4Content } from '../../assets/content/content'

function News4() {
  const selectedLang = getLanguage()
  const content = news4Content[selectedLang]

  return (
    <div className='news_item_body'>

      <div className='quote'>
        {content.quote1}
      </div>

      <div className='sub_title'>{content.sub_title1}</div>

      <div className='paragraph'>
        {content.paragraph1}
      </div>

      <div className='quote right'>
        {content.quote2}
      </div>

      <div className='paragraph'>
        {content.paragraph2}
      </div>

      <img className='img_full_width' src={require(`../../assets/images/news/news4.1.jpg`)} alt='News 4' />

      <div className='paragraph'>
        {content.paragraph3}
      </div>

      <div className='paragraph'>
        {content.paragraph4}
      </div>

      <div className='sub_title'>{content.sub_title2}</div>

      <div className='paragraph'>
        {content.paragraph5}
      </div>

      <div className='paragraph'>
        {content.paragraph6}
      </div>

      <div className='paragraph'>
        {content.paragraph7}
      </div>

      <div className='quote right'>
        {content.quote3}
      </div>

      <div className='paragraph'>
        {content.paragraph8}
      </div>

      <div className='paragraph'>
        {content.paragraph9}
      </div>

      <div className='paragraph'>
        {content.paragraph10}
      </div>

      <img className='img_full_width' src={require(`../../assets/images/news/news4.jpg`)} alt='News 4' />

      <div className='content_title'>{content.content_title}</div>

      <div className='sub_title'>{content.sub_title3}</div>

      <div className='paragraph'>
        {content.paragraph11}
      </div>

      <div className='sub_title'>{content.sub_title4}</div>

      <div className='paragraph'>
        {content.paragraph12}
      </div>

      <div className='paragraph'>
        {content.paragraph13}
      </div>

      <div className='paragraph'>
        {content.paragraph14}
      </div>

      <div className='sub_title'>{content.sub_title5}</div>

      <div className='paragraph'>
        {content.paragraph15}
      </div>

      <div className='paragraph'>
        {content.paragraph16}
      </div>

    </div>
  )
}

export default News4
