export const menuContent = {
  nl: {
    overOns: "over ons",
    projecten: "projecten",
    nieuws: "nieuws",
    // nieuws: "nieuws & artikels",
    vacatures: "vacatures",
    contact: "contact",
    esg: "duurzaamheid",
    invest: "investeer",
    contacteerOns: "Contacteer ons",
    maakEenAfspraak: "Maak een afspraak",
    menu: "menu",
    close: "close",
    goBack: "Terug naar vorige",
    stelEenVraag: "Stel een vraag",
  },
  fr: {
    overOns: "à propos de nous",
    projecten: "projets",
    nieuws: "nouvelles",
    // nieuws: "nouvelles & articles",
    vacatures: "offres d'emploi",
    contact: "contact",
    esg: "durabilité",
    invest: "investir",
    contacteerOns: "Contactez-nous",
    maakEenAfspraak: "Prendre rendez-vous",
    menu: "menu",
    close: "fermer",
    goBack: "Retour à la précédente",
    stelEenVraag: "Poser une question",
  },
  en: {
    overOns: "about us",
    projecten: "projects",
    nieuws: "news",
    // nieuws: "news & articles",
    vacatures: "jobs",
    contact: "contact",
    esg: "sustainability",
    invest: "invest",
    contacteerOns: "Contact us",
    maakEenAfspraak: "Make an appointment",
    menu: "menu",
    close: "close",
    goBack: "Back to previous",
    stelEenVraag: "Ask a question",
  }
}

export const contactFormContent = {
  nl: {
    title: "Neem contact op met ons",
    bel: "Bel",
    mail: "Mail",
    bezoek: "Bezoek",
    voornaam: "Voornaam",
    achternaam: "Familienaam",
    telefoon: "Telefoon",
    email: "Email",
    bericht: "Bericht",
    verzend: "verzend",
    berichtVerzonden: "bericht verzonden!",
    call: "bel",
    emailButton: "email",
    visit: "bezoek",
    disclaimerCheckMark: "Ik ga ermee akkoord dat mijn gegevens worden verwerkt in overeenstemming met het privacybeleid.",
    acceptDisclaimer: "Algemeen privacy beleid moet aanvaard worden",
    fillAllFields: "Gelieve alle velden in te vullen",
    validEmail: "Gelieve een geldig emailadres in te voeren",
  },
  fr: {
    title: "Contactez-nous",
    bel: "Appeler",
    mail: "Mail",
    bezoek: "Visitez",
    voornaam: "Prénom",
    achternaam: "Nom de famille",
    telefoon: "Téléphone",
    email: "Email",
    bericht: "Message",
    verzend: "envoyer",
    berichtVerzonden: "message envoyé!",
    call: "appeler",
    emailButton: "email",
    visit: "visite",
    disclaimerCheckMark: "J'accepte que mes données soient traitées conformément à la politique de confidentialité.",
    acceptDisclaimer: "La politique générale de confidentialité doit être acceptée",
    fillAllFields: "Veuillez remplir tous les champs",
    validEmail: "Veuillez entrer une adresse email valide",
  },
  en: {
    title: "Contact us",
    bel: "Call",
    mail: "Mail",
    bezoek: "Visit",
    voornaam: "First name",
    achternaam: "Last name",
    telefoon: "Phone",
    email: "Email",
    bericht: "Message",
    verzend: "send",
    berichtVerzonden: "message sent!",
    call: "call",
    emailButton: "email",
    visit: "visit",
    disclaimerCheckMark: "I agree that my data will be processed in accordance with the privacy policy.",
    acceptDisclaimer: "The general privacy policy must be accepted",
    fillAllFields: "Please fill in all fields",
    validEmail: "Please enter a valid email address",
  }
}

export const footerContent = {
  nl: {
    bel: "Bel",
    mail: "Mail",
    bezoek: "Kantoor",
    call: "bel",
    emailButton: "email",
    visit: "kantoor",
    disclaimer: "Juridisch",
  },
  fr: {
    bel: "Appeler",
    mail: "Mail",
    bezoek: "Bureau",
    call: "appeler",
    emailButton: "email",
    visit: "bureau",
    disclaimer: "Juridique",
  },
  en: {
    bel: "Call",
    mail: "Mail",
    bezoek: "Office",
    call: "call",
    emailButton: "email",
    visit: "office",
    disclaimer: "Legal",
  }
}

export const news3Content = {
  nl: {
    paragraph1: "Bij Vitruvi willen we dat iedereen zijn dromen najaagt, hoe groot of extreem ze ook zijn. Of het nu kampioen spelen is in uw sport of de zwaarste rally rijden van de wereld, wij geloven dat met een goeie brok ambitie, volharding en het streven naar perfectie alles mogelijk is. Met onze “Dare To Dream” campagne volgen we motorrijder Mathieu Liebaert.",
    paragraph2: "Mathieu is werkzaam in het familiale textielbedrijf Liebaert, dat gekend staat voor zijn elastische en fijn geweven stoffen voor high-end klanten. Hij is er verantwoordelijk voor het merk “RV-elite” dat focust op hoogstaande compressie leggings. Met deze leggings kunnen topsporters beter presteren en herstellen. Dit sluit perfect aan bij Mathieu als gepassioneerde sporter, met een grote voorliefde voor enduro motorrijden. En reeds enkele jaren dromende om deel te nemen aan de Dakar Rally.",
    paragraph3: "In 1979 werd de eerste Paris Dakar Rally georganiseerd onder het moto: \"Een uitdaging voor wie gaat, een droom voor degene die thuis blijft\". De toon was direct gezet om één van de zwaarste Rally’s van de wereld te worden. Een uitdaging die Mathieu reeds enkele jaren koestert. Op 1 januari is hij voor de eerste keer aan zijn avontuur begonnen, de 41ste editie die voor de derde keer op rij start in Jeddah (Saoedi-Arabië). Hij moet hierbij in 14 dagen een afstand van meer dan 8 000 km afleggen in de woestijn.",
    paragraph4: "Naast het rijden en navigeren, wordt er elke dag het uiterste gevraagd van de techniek van de motor en het lichaam. Daarom is het belangrijk voor de rijders om zich goed te omringen met een team van techniekers, personal coaches, sportartsen, ..",
    paragraph5: "Op het eerste zicht lijkt vastgoedontwikkeling en de Dakar rally misschien niets met elkaar gemeen te hebben. Maar eigenlijk zijn er veel gelijkenissen. Zo zijn de uitdagingen van vastgoedontwikkeling onder andere vernieuwen, samenwerken met een goed team, gefocust blijven,... Vandaar dat wij ongelooflijk fier zijn om Mathieu te kunnen sponsoren voor zijn droom. We wensen hem dan ook veel succes de komende dagen en we hopen alvast dat hij de finish mag halen."
  },
  fr: {
    paragraph1: "Chez Vitruvi, nous voulons que tout le monde poursuive ses rêves, aussi grands ou extrêmes soient-ils. Que ce soit devenir champion dans votre sport ou participer au rallye le plus difficile du monde, nous croyons qu'avec une bonne dose d'ambition, de persévérance et de quête de perfection, tout est possible. Avec notre campagne \"Dare To Dream\", nous suivons le motocycliste Mathieu Liebaert.",
    paragraph2: "Mathieu travaille dans l'entreprise textile familiale Liebaert, connue pour ses tissus élastiques et finement tissés pour des clients haut de gamme. Il est responsable de la marque \"RV-elite\" qui se concentre sur des leggings de compression de haute qualité. Avec ces leggings, les athlètes de haut niveau peuvent mieux performer et récupérer. Cela correspond parfaitement à Mathieu en tant que sportif passionné, avec une grande prédilection pour l'enduro. Et rêvant déjà depuis quelques années de participer au Dakar.",
    paragraph3: "En 1979, le premier Paris Dakar Rally a été organisé sous la devise : \"Un défi pour ceux qui y participent, un rêve pour ceux qui restent à la maison\". Le ton était directement donné pour devenir l'un des rallyes les plus difficiles du monde. Un défi que Mathieu chérissait déjà depuis quelques années. Le 1er janvier, il a commencé son aventure pour la première fois, la 41e édition qui démarre pour la troisième fois consécutive à Djeddah (Arabie Saoudite). Il doit parcourir plus de 8000 km dans le désert en 14 jours.",
    paragraph4: "En plus de conduire et de naviguer, chaque jour demande le maximum de la technique de la moto et du corps. Il est donc important pour les pilotes de bien s'entourer d'une équipe de techniciens, de coachs personnels, de médecins sportifs, etc.",
    paragraph5: "À première vue, le développement immobilier et le rallye Dakar n'ont peut-être rien en commun. Mais en réalité, il y a beaucoup de similitudes. Les défis du développement immobilier incluent notamment l'innovation, la collaboration avec une bonne équipe, rester concentré,... C'est pourquoi nous sommes incroyablement fiers de pouvoir sponsoriser Mathieu pour son rêve. Nous lui souhaitons beaucoup de succès dans les jours à venir et nous espérons qu'il atteindra la ligne d'arrivée."
  },
  en: {
    paragraph1: "At Vitruvi, we want everyone to pursue their dreams, no matter how big or extreme they are. Whether it's becoming a champion in your sport or driving the world's toughest rally, we believe that with a good chunk of ambition, perseverance, and striving for perfection, anything is possible. With our 'Dare To Dream' campaign, we follow motorcyclist Mathieu Liebaert.",
    paragraph2: "Mathieu works in the family textile company Liebaert, known for its elastic and finely woven fabrics for high-end customers. He is responsible for the brand 'RV-elite' which focuses on high-quality compression leggings. With these leggings, top athletes can perform and recover better. This fits perfectly with Mathieu as a passionate athlete, with a great love for enduro motorcycling. And already dreaming for several years of participating in the Dakar Rally.",
    paragraph3: "In 1979, the first Paris Dakar Rally was organized under the motto: 'A challenge for those who go, a dream for those who stay at home.' The tone was immediately set to become one of the toughest rallies in the world. A challenge that Mathieu has cherished for several years. On January 1, he started his adventure for the first time, the 41st edition which starts for the third time in a row in Jeddah (Saudi Arabia). He must cover a distance of more than 8,000 km in the desert in 14 days.",
    paragraph4: "In addition to driving and navigating, every day demands the utmost from the technique of the motorcycle and the body. Therefore, it is important for the riders to surround themselves with a team of technicians, personal coaches, sports doctors, etc.",
    paragraph5: "At first glance, real estate development and the Dakar rally may seem to have nothing in common. But actually, there are many similarities. The challenges of real estate development include innovation, working with a good team, staying focused,... That's why we are incredibly proud to be able to sponsor Mathieu for his dream. We wish him much success in the coming days and we hope that he will reach the finish."
  }
}

export const news4Content = {
  nl: {
    quote1: "Dare to Invest is een publicatie van Vitruvi over vastgoedonderwerpen en -trends, geschreven in een objectieve en no-nonsense stijl.",
    sub_title1: "Cijfers",
    paragraph1: "In augustus 2022 steeg de inflatie naar 9,94% en bereikt daarmee het hoogste niveau sinds maart 1976. De sterke stijging wordt voornamelijk getriggerd door de hoge energieprijzen. Zo is aardgas jaar op jaar 106,9% gestegen en elektriciteit 57,2%.",
    quote2: "In augustus 2022 steeg de inflatie naar 9,94%",
    paragraph2: "Als we kijken naar de residentiele vastgoedindex berekend door Statbel, dan zien we een stijging van 7% het laatste jaar.",
    paragraph3: "De abex-index die de evolutie van de kostprijs voor de bouw in beeld brengt is het afgelopen jaar 8,7% gestegen.",
    paragraph4: "Volgens de spaargids.be is de gemiddelde rente op 31 augustus voor hypothecaire kredieten 2,64%, wat een lichte daling is ten opzichte van 2 weken terug.",
    sub_title2: "Verwachtingen",
    paragraph5: "Wij verwachten dat de inflatie en de bouwprijzen niet gaan stabiliseren of zakken. De hogere energiekost zal doorgerekend worden in de algemene prijs en daarnaast zal de arbeidskost ook stijgen.",
    paragraph6: "Naar onze mening zal de rente enkele weken stabiliseren of licht dalen, waarna die terug zal stijgen doordat wij verwachten dat de ECB het rentetarief zal optrekken. Dit om de hoge inflatie tegen te gaan.",
    paragraph7: "Voor de vastgoedprijzen verwachten wij een stabilisering of een stijging aan een lager tempo dan de inflatie. Waardoor de reële prijsgroei negatief zal worden. Met als resultaat dat de waardering van het residentieel vastgoed een lichte correctie zal ondergaan.",
    quote3: "Voor de vastgoedprijzen verwachten wij een stabilisering of een stijging aan een lager tempo dan de inflatie.",
    paragraph8: "Door de indexeringen van de huurovereenkomsten zullen de huurinkomsten stijgen. Met als gevolg dat de huurrendementen in bestaande portefeuille stijgen en bij nieuwe aankopen gelijk blijft.",
    paragraph9: "Haaks op het bovenstaande, verwachten wij bij aanhoudende bouwkoststijging dat op korte termijn de grondprijzen licht kunnen dalen. Tenzij de vastgoedprijzen even snel of sneller gaan beginnen stijgen dan de bouwkost, wat wij op korte termijn niet zien gebeuren.",
    paragraph10: "Op vandaag zien we dat de spaarrente aangeboden door grootbanken rond de 0.10% is, wat beduidend lager is dan de inflatie. Voor het residentieel vastgoed zal de reële waarde mogelijks licht corrigeren. Maar is op heden minder dan de devalutie van cash. Daarnaast stijgen de huurinkomsten mee met de inflatie. Deze 2 factoren zorgen ervoor dat beleggen in onroerende goederen één van de beste mogelijkheden is om ons vermogen tegen de hoge inflatie te beschermen.",
    content_title: "Trends",
    sub_title3: "Stedelijke gebieden winnen terug aan populariteit",
    paragraph11: "Sinds het begin van de coronacrisis kenden woningen met een tuin en meer ruimte, voornamelijk gelegen in buitenstedelijke gebieden, een sterke vraag. Wat de prijzen van deze woning fors hoger duwde. Sinds een paar maanden zien we dat de vastgoedprijzen in stedelijke gebieden terug sneller stijgen dan de woningen in de plattelandsgebieden.",
    sub_title4: "De huurmarkt blijft groeien",
    paragraph12: "Er is ook een sterk toegenomen vraag naar kwalitatieve en energiezuinige huurpanden, waardoor wij verwachten dat de vraag naar residentieel nieuwbouw vastgoed zal stijgen.",
    paragraph13: "De huurmarkt is een markt met een sterke groei, mede doordat de vastgoedprijzen dermate gestegen zijn en het hierdoor moeilijker wordt voor velen om een eigen woning aan te kopen. Daarnaast wil de jonge generatie meer flexibiliteit in hun woonsituatie.",
    paragraph14: "Een nieuwbouwappartement met 2 slaapkamers op een goede locatie in een stad of dichtbij een stad is op vandaag terug het meest gewenste huurproduct. Sedert corona is een buitenruimte of terras zeker een extra troef.",
    sub_title5: "Tekort aan kwalitatieve en energiezuinige huurwoningen",
    paragraph15: "Er is een serieus tekort aan betere huurpanden, die voldoen aan de huidige comfort- en energienormen.",
    paragraph16: "Door de stijging van de energiekosten, wordt er nog meer aandacht besteed aan duurzaamheid en energiezuinigheid van vastgoed, maar men merkt op dat het aanbod op vandaag nog niet volgt. Hier is er dus zeker en vast nog werk aan de winkel."
  },
  fr: {
    quote1: "Dare to Invest est une publication de Vitruvi sur les sujets et tendances immobilières, rédigée dans un style objectif et sans fioritures.",
    sub_title1: "Chiffres",
    paragraph1: "En août 2022, l'inflation a grimpé à 9,94 %, atteignant ainsi son plus haut niveau depuis mars 1976. Cette forte augmentation est principalement déclenchée par les prix élevés de l'énergie. Par exemple, le gaz naturel a augmenté de 106,9 % d'une année sur l'autre et l'électricité de 57,2 %.",
    quote2: "En août 2022, l'inflation a grimpé à 9,94 %",
    paragraph2: "Si nous examinons l'indice des prix immobiliers résidentiels calculé par Statbel, nous constatons une augmentation de 7 % au cours de la dernière année.",
    paragraph3: "L'indice abex, qui reflète l'évolution du coût de la construction, a augmenté de 8,7 % au cours de l'année écoulée.",
    paragraph4: "Selon spaargids.be, le taux d'intérêt moyen au 31 août pour les crédits hypothécaires est de 2,64 %, ce qui représente une légère baisse par rapport à il y a deux semaines.",
    sub_title2: "Prévisions",
    paragraph5: "Nous nous attendons à ce que l'inflation et les prix de la construction ne se stabilisent pas ou ne baissent pas. Le coût énergétique plus élevé sera répercuté sur le prix général, et le coût de la main-d'œuvre augmentera également.",
    paragraph6: "À notre avis, les taux d'intérêt se stabiliseront ou baisseront légèrement pendant quelques semaines, avant de remonter car nous prévoyons que la BCE augmentera le taux d'intérêt pour contrer l'inflation élevée.",
    paragraph7: "Pour les prix de l'immobilier, nous prévoyons une stabilisation ou une augmentation à un rythme plus lent que l'inflation, ce qui entraînera une croissance réelle des prix négative. En conséquence, la valorisation de l'immobilier résidentiel subira une légère correction.",
    quote3: "Pour les prix de l'immobilier, nous prévoyons une stabilisation ou une augmentation à un rythme plus lent que l'inflation.",
    paragraph8: "En raison de l'indexation des baux, les revenus locatifs augmenteront. En conséquence, les rendements locatifs des portefeuilles existants augmenteront et resteront stables pour les nouvelles acquisitions.",
    paragraph9: "Contrairement à ce qui précède, nous prévoyons que, si les coûts de construction continuent d'augmenter, les prix des terrains pourraient légèrement baisser à court terme, à moins que les prix de l'immobilier ne commencent à augmenter aussi vite ou plus vite que les coûts de construction, ce que nous ne voyons pas se produire à court terme.",
    paragraph10: "Aujourd'hui, nous constatons que le taux d'épargne offert par les grandes banques est d'environ 0,10 %, ce qui est nettement inférieur à l'inflation. La valeur réelle de l'immobilier résidentiel pourrait légèrement corriger, mais reste inférieure à la dévaluation de la trésorerie. En outre, les revenus locatifs augmentent avec l'inflation. Ces deux facteurs font que l'investissement dans l'immobilier est l'une des meilleures options pour protéger notre patrimoine contre l'inflation élevée.",
    content_title: "Tendances",
    sub_title3: "Les zones urbaines gagnent à nouveau en popularité",
    paragraph11: "Depuis le début de la crise du coronavirus, les maisons avec jardin et plus d'espace, principalement situées dans des zones périphériques, ont connu une forte demande. Ce qui a fortement poussé les prix de ces maisons à la hausse. Depuis quelques mois, nous constatons que les prix de l'immobilier dans les zones urbaines augmentent à nouveau plus rapidement que ceux des maisons dans les zones rurales.",
    sub_title4: "Le marché locatif continue de croître",
    paragraph12: "Il y a également une demande accrue pour des propriétés locatives de qualité et écoénergétiques, nous nous attendons donc à ce que la demande de nouveaux logements résidentiels augmente.",
    paragraph13: "Le marché locatif est un marché en forte croissance, en partie parce que les prix de l'immobilier ont tellement augmenté qu'il est devenu plus difficile pour beaucoup d'acheter leur propre maison. De plus, la jeune génération souhaite plus de flexibilité dans leur situation de logement.",
    paragraph14: "Un appartement neuf avec 2 chambres à coucher bien situé dans une ville ou à proximité d'une ville est aujourd'hui le produit locatif le plus recherché. Depuis le coronavirus, un espace extérieur ou une terrasse est un atout supplémentaire.",
    sub_title5: "Pénurie de logements locatifs de qualité et économes en énergie",
    paragraph15: "Il y a une pénurie sérieuse de meilleures propriétés locatives, qui répondent aux normes actuelles de confort et d'énergie.",
    paragraph16: "En raison de l'augmentation des coûts énergétiques, une attention encore plus grande est accordée à la durabilité et à l'efficacité énergétique de l'immobilier, mais on constate que l'offre actuelle ne suit pas encore. Il y a donc encore beaucoup de travail à faire."
  },
  en: {
    quote1: "Dare to Invest is a publication by Vitruvi on real estate topics and trends, written in an objective and no-nonsense style.",
    sub_title1: "Figures",
    paragraph1: "In August 2022, inflation rose to 9.94%, reaching its highest level since March 1976. The sharp increase is mainly triggered by high energy prices. For example, natural gas has increased by 106.9% year-on-year and electricity by 57.2%.",
    quote2: "In August 2022, inflation rose to 9.94%",
    paragraph2: "If we look at the residential real estate index calculated by Statbel, we see an increase of 7% in the last year.",
    paragraph3: "The abex index, which shows the evolution of construction costs, has increased by 8.7% in the past year.",
    paragraph4: "According to spaargids.be, the average interest rate on August 31 for mortgage loans is 2.64%, which is a slight decrease compared to two weeks ago.",
    sub_title2: "Expectations",
    paragraph5: "We expect that inflation and construction prices will not stabilize or fall. The higher energy cost will be passed on in the general price, and labor costs will also increase.",
    paragraph6: "In our opinion, interest rates will stabilize or fall slightly for a few weeks, after which they will rise again as we expect the ECB to raise the interest rate to counter high inflation.",
    paragraph7: "For real estate prices, we expect a stabilization or an increase at a slower pace than inflation, resulting in negative real price growth. As a result, the valuation of residential real estate will undergo a slight correction.",
    quote3: "For real estate prices, we expect a stabilization or an increase at a slower pace than inflation.",
    paragraph8: "Due to the indexation of rental agreements, rental income will rise. As a result, rental yields in existing portfolios will increase and remain stable for new purchases.",
    paragraph9: "Contrary to the above, we expect that if construction costs continue to rise, land prices may slightly decrease in the short term. Unless real estate prices start to rise as fast or faster than construction costs, which we do not see happening in the short term.",
    paragraph10: "Today we see that the savings rate offered by major banks is around 0.10%, which is significantly lower than inflation. The real value of residential real estate may slightly correct but is currently less than the devaluation of cash. Additionally, rental income rises with inflation. These two factors make investing in real estate one of the best options to protect our wealth against high inflation.",
    content_title: "Trends",
    sub_title3: "Urban areas are gaining popularity again",
    paragraph11: "Since the start of the coronavirus crisis, houses with a garden and more space, mainly located in suburban areas, have seen strong demand, which pushed the prices of these homes significantly higher. In recent months, we have seen real estate prices in urban areas rise faster than houses in rural areas.",
    sub_title4: "The rental market continues to grow",
    paragraph12: "There is also a strong increase in demand for high-quality and energy-efficient rental properties, so we expect the demand for new residential real estate to rise.",
    paragraph13: "The rental market is a strong growth market, partly because real estate prices have risen so much that it has become more difficult for many to buy their own home. In addition, the young generation wants more flexibility in their living situation.",
    paragraph14: "A new two-bedroom apartment in a good location in a city or close to a city is once again the most sought-after rental product today. Since the coronavirus, outdoor space or a terrace is definitely an extra asset.",
    sub_title5: "Shortage of high-quality and energy-efficient rental homes",
    paragraph15: "There is a serious shortage of better rental properties that meet current comfort and energy standards.",
    paragraph16: "Due to the rise in energy costs, even more attention is paid to sustainability and energy efficiency of real estate, but it is noted that the current supply is not yet keeping up. So there is still work to be done."
  }
}

export const news5Content = {
  nl: {
    paragraph1: "Daarnaast zal Diggit StudentLife verantwoordelijk zijn voor het beheer van Spitter49. Diggit StudentLife is een toonaangevend bedrijf op het gebied van exploitatie van studentenhuisvesting en heeft uitgebreide kennis en ervaring in dit domein. We zijn blij dat zo’n professionele partij verantwoordelijk zal zijn voor het beheer van het project.",
    quote: "Spitter49 is een architecturale studentenvoorziening vastgoedontwikkeling gelegen in de bruisende studentenstad Kortrijk.",
    paragraph2: "Met Vitruvi blijven we ons verder inzetten voor de bouw en realisatie van dit prachtige project, zodat we samen met onze partners ervoor zorgen dat Spitter49 de nieuwe hotspot wordt voor de studenten. We zijn ervan overtuigd dat deze samenwerking zal leiden tot de beste ervaringen voor zowel toekomstige huurders als investeerders.",
    paragraph3: "Spitter49 is een architecturale studentenvoorziening vastgoedontwikkeling gelegen in de bruisende studentenstad Kortrijk. Het project zal voorzien zijn van hoogwaardige studentenkamers met eigen sanitair, een gemeenschappelijke binnentuin, ruime fietsenstalling, een groot dakterras en vele gemeenschappelijke ruimten. Tijdens het ontwerp werd er rekening gehouden met alle huidige en toekomstige energienormen zoals een hybride systeem voor verwarming met geothermie. Kortom, Spitter49 zal de nieuwe standaard worden voor studentenhuisvesting.",
  },
  fr: {
    paragraph1: "De plus, Diggit StudentLife sera responsable de la gestion de Spitter49. Diggit StudentLife est une entreprise leader dans l'exploitation de logements étudiants et possède une connaissance et une expérience étendues dans ce domaine. Nous sommes heureux qu'une partie aussi professionnelle soit responsable de la gestion du projet.",
    quote: "Spitter49 est un développement immobilier de logements étudiants architecturaux situé dans la ville étudiante animée de Courtrai.",
    paragraph2: "Avec Vitruvi, nous continuons à nous engager pour la construction et la réalisation de ce magnifique projet, afin qu'avec nos partenaires nous assurions que Spitter49 devienne le nouveau hotspot pour les étudiants. Nous sommes convaincus que cette collaboration conduira aux meilleures expériences pour les futurs locataires comme pour les investisseurs.",
    paragraph3: "Spitter49 est un développement immobilier de logements étudiants architecturaux situé dans la ville étudiante animée de Courtrai. Le projet comprendra des chambres étudiantes de haute qualité avec sanitaires privés, un jardin commun, un grand parking pour vélos, une grande terrasse sur le toit et de nombreux espaces communs. Lors de la conception, toutes les normes énergétiques actuelles et futures ont été prises en compte, comme un système hybride pour le chauffage avec géothermie. En bref, Spitter49 deviendra la nouvelle norme pour les logements étudiants.",
  },
  en: {
    paragraph1: "Additionally, Diggit StudentLife will be responsible for the management of Spitter49. Diggit StudentLife is a leading company in the field of student housing operations and has extensive knowledge and experience in this domain. We are pleased that such a professional party will be responsible for managing the project.",
    quote: "Spitter49 is an architectural student housing real estate development located in the bustling student city of Kortrijk.",
    paragraph2: "With Vitruvi, we continue to commit ourselves to the construction and realization of this beautiful project, ensuring with our partners that Spitter49 becomes the new hotspot for students. We are confident that this collaboration will lead to the best experiences for both future tenants and investors.",
    paragraph3: "Spitter49 is an architectural student housing real estate development located in the bustling student city of Kortrijk. The project will feature high-quality student rooms with private bathrooms, a communal garden, ample bicycle storage, a large rooftop terrace, and many communal areas. During the design, all current and future energy standards were taken into account, such as a hybrid system for heating with geothermal energy. In short, Spitter49 will become the new standard for student housing.",
  }
}

export const homePageContent = {
  nl: {
    slogan: ['connect.', 'respect.', 'create'],
    subSlogan: 'real estate',
    quote: "“Beauty is produced by the pleasing appearance and good taste of the whole, and by the dimensions of all the parts being duly proportioned to each other.”",
    quoteAuthor: "―Vitruvius―",
    homeProjectsButton: "ontdek onze projecten",
    aboutTitle: ['Over', 'ons'],
    aboutSubTitle: 'Wij realiseren plaatsen om te wonen & werken',
    aboutParagraph1: "Vitruvi engageert zich om de <strong>leefkwaliteit</strong> van gemeenschappen continu te verhogen door doordacht en kwalitatief leefomgevingen te <strong>herontwikkelen</strong>, te <strong>bouwen</strong> en te <strong>beheren</strong>, met respect voor de omgeving en haar geschiedenis",
    aboutParagraph2: "Vitruvi is een verkorting van de Romeinse architect Vitruvius, beter bekend als auteur van “De bouwkunst, in tien delen”, het enige bewaarde naslagwerk over de Romeinse architectuur.",
    aboutParagraph3: "Vitruvius wordt tot op vandaag geroemd voor zijn visie over perfecte proporties. Zelfs Leonardo da Vinci baseerde zijn werk de “Vitruviaanse man” hierop. Volgens Vitruvius moet een gebouw minimum aan 3 waarden voldoen: firmitas, utilitas en venustas, vertaald: <strong>stevig, gebruiksvriendelijk en mooi</strong>. Waarden waarmee wij ons onderscheiden in onze realisaties.",
    projectTitle: ['Project', 'In de kijker'],
    projectButton1: 'Meer info',
    projectButton2: 'Alle projecten',
    newsTitle: ['Vitruvi', 'nieuws'],
    // newsTitle: ['Vitruvi', 'nieuws & Artikels'],
    newsButton1: 'Lees meer',
    newsButton2: 'Alle nieuwsartikels',
    projectsButton: 'Meer info',
  },
  fr: {
    slogan: ['connect.', 'respect.', 'create'],
    subSlogan: 'real estate',
    quote: "“La beauté est produite par l'apparence plaisante et le bon goût de l'ensemble, et par les dimensions de toutes les parties étant dûment proportionnées les unes aux autres.”",
    quoteAuthor: "―Vitruvius―",
    homeProjectsButton: "découvrez nos projets",
    aboutTitle: ['À propos', 'de nous'],
    aboutSubTitle: "Nous réalisons des lieux pour vivre et travailler",
    aboutParagraph1: "Vitruvi s'engage à améliorer en continu la <strong>qualité de vie</strong> des communautés en redéveloppant, reconstruisant et gérant de manière réfléchie et qualitative les environnements de vie, avec respect pour l'environnement et son histoire.",
    aboutParagraph2: "Vitruvi est une abréviation de l'architecte romain Vitruve, mieux connu comme l'auteur de 'De architectura', le seul ouvrage de référence conservé sur l'architecture romaine.",
    aboutParagraph3: "Vitruve est encore aujourd'hui célébré pour sa vision des proportions parfaites. Même Léonard de Vinci a basé son œuvre 'L'Homme de Vitruve' sur celle-ci. Selon Vitruve, un bâtiment doit répondre à au moins 3 valeurs : firmitas, utilitas et venustas, traduit : <strong>solide, fonctionnel et beau</strong>. Des valeurs qui nous distinguent dans nos réalisations.",
    projectTitle: ['Projet', 'À la une'],
    projectButton1: 'Plus d\'infos',
    projectButton2: 'Tous les projets',
    newsTitle: ['Vitruvi', 'nouvelles'],
    // newsTitle: ['Vitruvi', 'nouvelles & Articles'],
    newsButton1: 'Lire plus',
    newsButton2: 'Tous les articles',
    projectsButton: 'Plus d\'infos',
  },
  en: {
    slogan: ['connect.', 'respect.', 'create'],
    subSlogan: 'real estate',
    quote: "“Beauty is produced by the pleasing appearance and good taste of the whole, and by the dimensions of all the parts being duly proportioned to each other.”",
    quoteAuthor: "―Vitruvius―",
    homeProjectsButton: "discover our projects",
    aboutTitle: ['About', 'us'],
    aboutSubTitle: "We create places to live and work",
    aboutParagraph1: "Vitruvi is committed to continuously enhancing the <strong>quality of life</strong> of communities by thoughtfully and qualitatively redeveloping, rebuilding, and managing living environments, with respect for the environment and its history.",
    aboutParagraph2: "Vitruvi is a shortened form of the Roman architect Vitruvius, best known as the author of 'De Architectura,' the only surviving reference work on Roman architecture.",
    aboutParagraph3: "Vitruvius is still praised today for his vision of perfect proportions. Even Leonardo da Vinci based his work 'The Vitruvian Man' on it. According to Vitruvius, a building must meet at least 3 values: firmitas, utilitas, and venustas, translated: <strong>solid, functional, and beautiful</strong>. These are the values that distinguish us in our projects.",
    projectTitle: ['Featured', 'Project'],
    projectButton1: 'More info',
    projectButton2: 'All projects',
    newsTitle: ['Vitruvi', 'News'],
    // newsTitle: ['Vitruvi', 'News & Articles'],
    newsButton1: 'Read more',
    newsButton2: 'All news articles',
    projectsButton: 'More info',
  }
}

export const jobsPageContent = {
  nl: {
    pageTitle: "Vacatures",
    noJobs: "Momenteel hebben wij geen vacatures openstaan.",
    contactMessage: "Maar als je een passie hebt voor vastgoed en interesse hebt in ons bedrijf, wacht dan niet om contact met ons op te nemen.",
  },
  fr: {
    pageTitle: "Offres d'emploi",
    noJobs: "Actuellement, nous n'avons pas de postes vacants.",
    contactMessage: "Mais si vous avez une passion pour l'immobilier et que vous êtes intéressé par notre entreprise, n'hésitez pas à nous contacter.",
  },
  en: {
    pageTitle: "Job vacancies",
    noJobs: "Currently, we have no job openings.",
    contactMessage: "But if you have a passion for real estate and are interested in our company, don't hesitate to contact us.",
  }
}

export const investPageContent = {
  nl: {
    pageTitle: "Investeren In Vastgoed",
    subtitle: "Investeer met vertrouwen, met Vitruvi aan uw zijde",
    openingParagraph: "In de complexe wereld van vastgoedbeleggingen is het belangrijk om over de juiste informatie en ondersteuning te beschikken. De Vitruvi Investor Club biedt u precies dat. Door deel te nemen aan onze club, krijgt u toegang tot exclusieve voordelen die speciaal zijn ontworpen om uw investeringsstrategieën te versterken en uw rendement te maximaliseren. Samen bouwen we aan een succesvolle en winstgevende toekomst.",
    benefits: [
      {
        title: "Exclusieve Content",
        text: "Ontvang exclusieve marktanalyses, investeringsstrategieën en updates via onze mailinglist."
      },
      {
        title: "Eerste Keus",
        text: "Krijg als eerste toegang tot nieuwe investeringsmogelijkheden voordat ze op de markt komen."
      },
      {
        title: "Investeringsanalyse",
        text: "Ontvang een persoonlijke investeringsanalyse, afgestemd op uw specifieke behoeften en doelen."
      },
      {
        title: "Netwerk Evenementen",
        text: "Verbind met gelijkgestemde investeerders en vastgoedexperts tijdens exclusieve netwerk evenementen."
      },
      {
        title: "Persoonlijk Advies",
        text: "Toegang tot persoonlijk advies van onze ervaren vastgoedprofessionals voor al uw investeringsbehoeften."
      }
    ],
    formTitle: "Schrijf u in voor onze investors club"
  },
  fr: {
    pageTitle: "Investir dans l'Immobilier",
    subtitle: "Investissez en toute confiance avec Vitruvi à vos côtés",
    openingParagraph: "Dans le monde complexe de l'investissement immobilier, il est crucial d'avoir les bonnes informations et le bon soutien. Le Club des Investisseurs Vitruvi vous offre exactement cela. En rejoignant notre club, vous accédez à des avantages exclusifs spécialement conçus pour renforcer vos stratégies d'investissement et maximiser vos rendements. Ensemble, nous construisons un avenir prospère et rentable.",
    benefits: [
      {
        title: "Contenu Exclusif",
        text: "Recevez des analyses de marché exclusives, des stratégies d'investissement et des mises à jour via notre liste de diffusion."
      },
      {
        title: "Priorité d'Accès",
        text: "Accédez en premier aux nouvelles opportunités d'investissement avant qu'elles n'arrivent sur le marché."
      },
      {
        title: "Analyse d'Investissement",
        text: "Obtenez une analyse d'investissement personnalisée, adaptée à vos besoins et objectifs spécifiques."
      },
      {
        title: "Événements de Réseautage",
        text: "Connectez-vous avec des investisseurs partageant les mêmes idées et des experts immobiliers lors d'événements de réseautage exclusifs."
      },
      {
        title: "Conseil Personnalisé",
        text: "Accédez à des conseils personnalisés de nos professionnels immobiliers expérimentés pour tous vos besoins d'investissement."
      }
    ],
    formTitle: "Inscrivez-vous à notre club d'investisseurs"
  },
  en: {
    pageTitle: "Investing in Real Estate",
    subtitle: "Invest with confidence, with Vitruvi by your side",
    openingParagraph: "In the complex world of real estate investments, it's crucial to have the right information and support. The Vitruvi Investor Club offers you exactly that. By joining our club, you gain access to exclusive benefits specially designed to strengthen your investment strategies and maximize your returns. Together, we build a successful and profitable future.",
    benefits: [
      {
        title: "Exclusive Content",
        text: "Receive exclusive market analyses, investment strategies, and updates through our mailing list."
      },
      {
        title: "First Choice",
        text: "Get first access to new investment opportunities before they hit the market."
      },
      {
        title: "Investment Analysis",
        text: "Receive a personal investment analysis tailored to your specific needs and goals."
      },
      {
        title: "Networking Events",
        text: "Connect with like-minded investors and real estate experts during exclusive networking events."
      },
      {
        title: "Personal Advice",
        text: "Access to personal advice from our experienced real estate professionals for all your investment needs."
      }
    ],
    formTitle: "Sign up for our investors club"
  }
};

export const esgPageContent = {
  nl: {
    pageTitle: "Vitruvi & Duurzaamheid",
    paragraphs: [
      {
        "subtitle": "",
        "content": "Vitruvi engageert zich om de leefkwaliteit van gemeenschappen continu te verhogen door doordacht en kwalitatief leefomgevingen te herontwikkelen, te herbouwen en te beheren, met respect voor de omgeving en haar geschiedenis.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "WAT IS VITRUVI ZIJN VISIE VOOR DUURZAME VASTGOEDONTWIKKELING",
        "content": "In een wereld waarin milieubewustzijn en de zoektocht naar duurzame oplossingen steeds belangrijker worden, omarmt Vitruvi het begrip duurzaamheid in al zijn facetten. Voor Vitruvi gaat duurzaamheid verder dan het louter implementeren van hernieuwbare energiebronnen. Het is een alomvattende benadering, waarbij gestreefd wordt naar een harmonieuze balans tussen ecologie, economie en tijdloosheid.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "ECOLOGIE: HET FUNDAMENT VAN DUURZAAMHEID",
        "content": "Bij Vitruvi vormt ecologie het fundament van duurzaamheid. Het bedrijf erkent dat duurzame ontwikkeling begint met het begrijpen en respecteren van lokale ecosystemen. Dit betekent niet alleen het integreren van zonnepanelen en warmtepompen, maar ook het minimaliseren van de schadelijke impact op het milieu. Vitruvi voert uitgebreide milieueffectrapportages uit voor elk project en streeft ernaar om de biodiversiteit te behouden en te bevorderen. Door samen te werken met ecologen en natuurbeschermingsorganisaties zorgt Vitruvi ervoor dat hun projecten in harmonie zijn met de omringende natuur.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "ECONOMISCHE DUURZAAMHEID: EEN LANGETERMIJNVISIE",
        "content": "Voor Vitruvi gaat economische duurzaamheid verder dan de initiële bouwkosten. Het bedrijf omarmt een langetermijnvisie door middel van levenscyclusanalyses en het behoud van waarde op de lange termijn. Dit betekent zorgvuldige materiaalselectie, waarbij de voorkeur wordt gegeven aan gerecyclede en recycleerbare materialen om de ecologische voetafdruk te minimaliseren. Daarnaast streeft Vitruvi naar efficiënt gebruik van materialen en energiebronnen, wat resulteert in lagere operationele kosten en een verlengde levensduur van gebouwen. Door te investeren in duurzame materialen en energie-efficiënte technologieën, creëert Vitruvi gebouwen die zowel financieel als ecologisch veerkrachtig zijn.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "TIJDLOOSHEID: EEN HOEKSTEEN VAN DUURZAME ARCHITECTUUR",
        "content": "Een ander cruciaal aspect van duurzaamheid voor Vitruvi is tijdloosheid. Terwijl modetrends komen en gaan, streeft Vitruvi ernaar om gebouwen te creëren die esthetisch en functioneel relevant blijven door de jaren heen. Dit vereist een diepgaand begrip van de cultuur, geschiedenis en evolutie van de omgeving waarin het gebouw zich bevindt. Door tijdloze ontwerpen te combineren met moderne technologieën en duurzame materialen, zorgt Vitruvi ervoor dat hun gebouwen een blijvende impact hebben op zowel de omgeving als de gemeenschappen die ze bedienen.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "DOORDACHTE ONTWIKKELING EN SAMENWERKING",
        "content": "Bij Vitruvi is doordachte ontwikkeling de ruggengraat van elk project. Het bedrijf gelooft in een alomvattende benadering, waarbij architecten, ingenieurs en ontwerpers nauw samenwerken om gebouwen te creëren die niet alleen voldoen aan de huidige behoeften, maar ook veerkrachtig zijn tegenover veranderende omstandigheden. Flexibele indelingen, efficiënt ruimtegebruik en geïntegreerde technologieën dragen bij aan de bruikbaarheid en duurzaamheid van het gebouw. Vitruvi streeft ernaar om gemeenschappen te creëren die gedijen en evolueren met de behoeften van hun bewoners, terwijl ze tegelijkertijd de ecologische impact minimaliseren.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "CONCLUSIE",
        "content": "Als voortrekker in duurzame vastgoedontwikkeling belichaamt Vitruvi de ware geest van duurzaamheid. Door ecologie, economie en tijdloosheid in evenwicht te brengen, creëert het bedrijf gebouwen die niet alleen voldoen aan de behoeften van de huidige generatie, maar ook de mogelijkheden van toekomstige generaties niet belemmeren. Vitruvi zet zich in voor een duurzame toekomst, waarin mens en milieu in harmonie samenleven.",
        "image": "",
        "image_position": ""
      }
    ]
  },
  fr: {
    pageTitle: "Vitruvi & Durabilité",
    paragraphs: [
      {
        "subtitle": "",
        "content": "Vitruvi s'engage à améliorer continuellement la qualité de vie des communautés en réaménageant, reconstruisant et gérant des environnements de vie de manière réfléchie et de qualité, dans le respect de l'environnement et de son histoire.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "QUELLE EST LA VISION DE VITRUVI POUR UN DÉVELOPPEMENT IMMOBILIER DURABLE",
        "content": "Dans un monde où la sensibilisation à l'environnement et la recherche de solutions durables deviennent de plus en plus importantes, Vitruvi embrasse le concept de durabilité dans tous ses aspects. Pour Vitruvi, la durabilité va au-delà de la simple mise en œuvre de sources d'énergie renouvelables. C'est une approche globale qui vise à trouver un équilibre harmonieux entre écologie, économie et intemporalité.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "ÉCOLOGIE : LE FONDEMENT DE LA DURABILITÉ",
        "content": "Chez Vitruvi, l'écologie est le fondement de la durabilité. L'entreprise reconnaît que le développement durable commence par comprendre et respecter les écosystèmes locaux. Cela signifie non seulement l'intégration de panneaux solaires et de pompes à chaleur, mais aussi la minimisation de l'impact néfaste sur l'environnement. Vitruvi réalise des études d'impact environnemental approfondies pour chaque projet et s'efforce de préserver et de promouvoir la biodiversité. En collaborant avec des écologistes et des organisations de protection de la nature, Vitruvi veille à ce que ses projets soient en harmonie avec la nature environnante.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "DURABILITÉ ÉCONOMIQUE : UNE VISION À LONG TERME",
        "content": "Pour Vitruvi, la durabilité économique va au-delà des coûts de construction initiaux. L'entreprise adopte une vision à long terme grâce à des analyses de cycle de vie et à la préservation de la valeur à long terme. Cela signifie une sélection minutieuse des matériaux, avec une préférence pour les matériaux recyclés et recyclables afin de minimiser l'empreinte écologique. De plus, Vitruvi s'efforce d'utiliser efficacement les matériaux et les ressources énergétiques, ce qui se traduit par des coûts opérationnels réduits et une durée de vie prolongée des bâtiments. En investissant dans des matériaux durables et des technologies énergétiquement efficaces, Vitruvi crée des bâtiments résilients tant sur le plan financier qu'écologique.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "INTEMPORALITÉ : UNE PIERRE ANGULAIRE DE L'ARCHITECTURE DURABLE",
        "content": "Un autre aspect crucial de la durabilité pour Vitruvi est l'intemporalité. Alors que les tendances de la mode vont et viennent, Vitruvi s'efforce de créer des bâtiments qui restent esthétiquement et fonctionnellement pertinents au fil des ans. Cela nécessite une compréhension approfondie de la culture, de l'histoire et de l'évolution de l'environnement dans lequel le bâtiment est situé. En combinant des conceptions intemporelles avec des technologies modernes et des matériaux durables, Vitruvi veille à ce que ses bâtiments aient un impact durable à la fois sur l'environnement et sur les communautés qu'ils desservent.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "DÉVELOPPEMENT RÉFLÉCHI ET COLLABORATION",
        "content": "Chez Vitruvi, le développement réfléchi est la colonne vertébrale de chaque projet. L'entreprise croit en une approche globale, où architectes, ingénieurs et concepteurs travaillent en étroite collaboration pour créer des bâtiments qui répondent non seulement aux besoins actuels, mais qui sont également résilients face à l'évolution des conditions. Des aménagements flexibles, une utilisation efficace de l'espace et des technologies intégrées contribuent à la fonctionnalité et à la durabilité du bâtiment. Vitruvi s'efforce de créer des communautés qui prospèrent et évoluent avec les besoins de leurs habitants, tout en minimisant l'impact écologique.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "CONCLUSION",
        "content": "En tant que pionnier du développement immobilier durable, Vitruvi incarne le véritable esprit de la durabilité. En équilibrant écologie, économie et intemporalité, l'entreprise crée des bâtiments qui répondent non seulement aux besoins de la génération actuelle, mais qui n'entravent pas les possibilités des générations futures. Vitruvi s'engage pour un avenir durable, où l'homme et l'environnement vivent en harmonie.",
        "image": "",
        "image_position": ""
      }
    ]
  },
  en: {
    pageTitle: "Vitruvi & Sustainability",
    paragraphs: [
      {
        "subtitle": "",
        "content": "Vitruvi is committed to continuously improving the quality of life in communities by thoughtfully and qualitatively redeveloping, rebuilding, and managing living environments, with respect for the environment and its history.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "WHAT IS VITRUVI'S VISION FOR SUSTAINABLE REAL ESTATE DEVELOPMENT",
        "content": "In a world where environmental awareness and the search for sustainable solutions are becoming increasingly important, Vitruvi embraces the concept of sustainability in all its aspects. For Vitruvi, sustainability goes beyond merely implementing renewable energy sources. It is a comprehensive approach, striving for a harmonious balance between ecology, economy, and timelessness.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "ECOLOGY: THE FOUNDATION OF SUSTAINABILITY",
        "content": "At Vitruvi, ecology forms the foundation of sustainability. The company recognizes that sustainable development begins with understanding and respecting local ecosystems. This means not only integrating solar panels and heat pumps but also minimizing harmful environmental impacts. Vitruvi conducts extensive environmental impact assessments for each project and strives to preserve and promote biodiversity. By collaborating with ecologists and conservation organizations, Vitruvi ensures that its projects are in harmony with the surrounding nature.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "ECONOMIC SUSTAINABILITY: A LONG-TERM VISION",
        "content": "For Vitruvi, economic sustainability goes beyond initial construction costs. The company embraces a long-term vision through life cycle assessments and long-term value retention. This means careful material selection, favoring recycled and recyclable materials to minimize the ecological footprint. Additionally, Vitruvi strives for efficient use of materials and energy resources, resulting in lower operational costs and extended building lifespans. By investing in sustainable materials and energy-efficient technologies, Vitruvi creates buildings that are resilient both financially and ecologically.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "TIMELESSNESS: A CORNERSTONE OF SUSTAINABLE ARCHITECTURE",
        "content": "Another crucial aspect of sustainability for Vitruvi is timelessness. While fashion trends come and go, Vitruvi strives to create buildings that remain aesthetically and functionally relevant over the years. This requires a deep understanding of the culture, history, and evolution of the environment in which the building is situated. By combining timeless designs with modern technologies and sustainable materials, Vitruvi ensures that their buildings have a lasting impact on both the environment and the communities they serve.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "THOUGHTFUL DEVELOPMENT AND COLLABORATION",
        "content": "At Vitruvi, thoughtful development is the backbone of every project. The company believes in a comprehensive approach, where architects, engineers, and designers work closely together to create buildings that not only meet current needs but are also resilient to changing conditions. Flexible layouts, efficient use of space, and integrated technologies contribute to the functionality and sustainability of the building. Vitruvi strives to create communities that thrive and evolve with the needs of their residents, while minimizing ecological impact.",
        "image": "",
        "image_position": ""
      },
      {
        "subtitle": "CONCLUSION",
        "content": "As a pioneer in sustainable real estate development, Vitruvi embodies the true spirit of sustainability. By balancing ecology, economy, and timelessness, the company creates buildings that not only meet the needs of the current generation but also do not hinder the possibilities of future generations. Vitruvi is committed to a sustainable future, where people and the environment live in harmony.",
        "image": "",
        "image_position": ""
      }
    ]
  }
}


export const newsOverviewPageContent = {
  nl: {
    pageTitle: "Nieuws & artikels",
    by: "door",
  },
  fr: {
    pageTitle: "Nouvelles & articles",
    by: "par",
  },
  en: {
    pageTitle: "News & articles",
    by: "by",
  }
}

export const projectPageContent = {
  nl: {
    location: "Locatie",
    unique: "Uniek",
    units: "Units",
    website: "Website",
    status: "Status",
    architect: "Architect",
  },
  fr: {
    location: "Emplacement",
    unique: "Unique",
    units: "Units",
    website: "Site Web",
    status: "Statut",
    architect: "Architecte",
  },
  en: {
    location: "Location",
    unique: "Unique",
    units: "Units",
    website: "Website",
    status: "Status",
    architect: "Architect",
  }
}

export const legalPageContent = {
  nl: {
    pageTitle: "Juridisch",
    legal: {
      subTitle: "WETTELIJKE VERMELDINGEN",
      paragraphs: [
        "Het gebruik van de website is gebonden aan de naleving van de hieronder beschreven algemene voorwaarden. Door op de website van Vitruvi te surfen, verklaart u te hebben kennisgenomen van deze algemene gebruiksvoorwaarden, en ze zonder enig voorbehoud te hebben aanvaard.",
        "Vitruvi besteedt veel aandacht aan de ontwikkeling en de actualisering van deze website. Vitruvi kan echter niet garanderen dat de informatie op de website correct is. Onnauwkeurigheden en weglatingen kunnen dus nooit volledig worden uitgesloten. Net als de mogelijke niet-actualisering van de inhoud van de website moet de bezoeker dit altijd in het achterhoofd houden.",
        "Alle inlichtingen met betrekking tot projecten zijn vrijblijvend en onder voorbehoud van verkoop.",
        "De gebruiker weet dat de informatie zonder voorafgaande kennisgeving kan worden gewijzigd, en telkens in de vorm van ruwe, niet-geïndividualiseerde gegevens wordt aangeboden. Deze gegevens kunnen dus op zich enkel worden gebruikt ter algemene informatie.",
        "Ingeval er links naar andere websites worden voorzien, kan Vitruvi in geen geval aansprakelijk worden gesteld voor de inhoud en het bestaan van die andere websites.",
        "Vitruvi wijst iedere verantwoordelijkheid van de hand met betrekking tot de inhoud van de website en het gebruik ervan.",
        "Het gebruik van de website verleent de gebruiker geen enkel intellectueel eigendomsrecht met betrekking tot de inhoud van de website. Die blijft uitsluitend eigendom van Vitruvi.",
        "De informatie die op de website kan worden geraadpleegd, de grafische vormgeving, de teksten, tekeningen, foto’s, gegevens, logo’s, merken en andere elementen die op de website worden weergegeven, zijn voorbehouden en beschermd door het merkenrecht en/of het auteursrecht en/of andere geldende voorschriften die van toepassing zouden zijn op het vlak van intellectueel eigendom.",
        "Het kopiëren, aanpassen, vertalen, bewerken, openbaar maken, verspreiden of wijzigen van de volledige website of een deel ervan, of van gelijkende elementen, in welke vorm en op welke manier ook, is streng verboden, behoudens voorafgaande en schriftelijke toestemming van Vitruvi.",
        "Bij wijze van uitzondering, en in overeenstemming met de bepalingen van de wet van 30 juni 1994 betreffende auteursrechten en de naburige rechten, hebt u het recht om de informatie op deze website te downloaden en te kopiëren voor strikt persoonlijk gebruik.",
        "Ongeoorloofd gebruik van de volledige website of een deel ervan zal aanleiding kunnen geven tot rechtsvervolging in overeenstemming met de geldende wetten. Vitruvi garandeert dat uw persoonlijke gegevens buiten uw medeweten niet zullen worden verzameld, bewaard of gemanipuleerd bij het downloaden van een pagina van haar website.",
        "Als dergelijke gegevens ons echter ter kennis worden gebracht bij een vrijwillig contact van uwentwege, verbindt Vitruvi zich ertoe om ze strikt geheim te houden, ze niet te verkopen aan of op een andere manier ter beschikking te stellen van derden, en om zelfs nog maar het bestaan ervan niet te onthullen in haar registers.",
        "Deze gegevens zullen enkel worden gebruikt om u op de hoogte te brengen van de nieuwigheden en de aanbiedingen op onze website, als u daar uitdrukkelijk de toestemming voor hebt gegeven.",
        "U hebt het recht om op eenvoudig verzoek geregistreerde persoonlijke gegevens uit onze registers te laten schrappen of ze te laten wijzigen. De gevraagde verwerking zal binnen de 5 dagen na het verzoek worden bevestigd.",
      ],
    },
    policy: {
      subTitle: "PRIVACY POLICY",
      paragraphs: [
        "Wanneer u een onroerend goed koopt, u ons producten of diensten verleent, u informatie wenst te ontvangen over Vitruvi of u van onze diensten geniet, of wanneer u onze website [www.vitruvi.be] (de “Website”), bezoekt, zullen wij bepaalde persoonsgegevens over u (“Persoonsgegevens”) verzamelen.",
        "Dit beleid inzake bescherming van de persoonlijke levenssfeer (het “Beleid”) is erop gericht u erover te informeren hoe wij, de Vitruvi en het geheel van haar dochterondernemingen (“Vitruvi” , “onze” “ of “wij”) persoonsgegevens verzamelen in dat kader, waarom wij dat doen, met welke derden wij ze delen, gedurende welke tijd wij ze bewaren, welke rechten u hebt en op welke manier u ze kunt uitoefenen.",
        "<strong>1. Wie zijn wij?</strong> Vitruvi is verwerkingsverantwoordelijke van uw persoonsgegevens. Haar maatschappelijke zetel is gevestigd te 8760, Meulebeke, Ooigemstraat 47 en zij is ingeschreven in de KBO onder nummer 0726.899.885. U kunt ons contacteren via de volgende algemene contactgegevens: E-mail: info@Vitruvi.be",
        // Continue with the rest of the privacy policy paragraphs
      ],
    },
    cookies: {
      subTitle: "COOKIE POLICY",
      paragraphs: [
        "Op de Vitruvi website wordt gebruik gemaakt van cookies. Dat zijn kleine tekstbestanden die worden aangemaakt door een programma op de server van deze website, maar bewaard op (de harde schijf van) uw computer. Cookies zijn minstens voor een deel technisch noodzakelijk voor een vlotte communicatie tussen uw computer en de website. Cookies kunnen evenwel ook nuttig zijn (bv. het bewaren van voorkeurinstellingen) en bepaalde gegevens kunnen doorgestuurd worden naar deze website om de werking ervan te beoordelen en te verbeteren. Als u de cookie – door uw browserinstellingen – aanvaardt, gaat Vitruvi ervan uit dat u, als u voort surft, toestemt met de gegevensverwerking die daaruit voortvloeit. Meer informatie over cookies en hoe u ze, naargelang van het gebruikte type van browser, kunt verwijderen, vindt u op cookiesandyou.com.",
        "<strong>GOOGLE ANALYTICS</strong>",
        "De Vitruvi website maakt ook gebruik van Google Analytics, een web-analysedienst die wordt aangeboden door Google Inc. ( 'Google'). Google Analytics gebruikt 'Cookies', tekstbestanden die op computers worden opgeslagen en het mogelijk maken om uw gebruik van de website te analyseren.",
        "De gegevens die het cookie genereert over uw gebruik van deze website worden meestal overgebracht naar een server van Google in de VS. Daar kunnen ze opgeslagen worden. De zogenaamde IP-anonimisering is ingeschakeld, zodat uw IP-adres binnen de Europese Unie en andere verdragslanden van de Europese Economische Ruimte eerst door Google verkort wordt. Slechts in uitzonderlijke gevallen wordt het volledige IP-adres naar een server van Google in de VS verzonden en daar verkort.",
        "In onze opdracht gebruikt Google deze data om uw gebruik van de website te evalueren, om rapporten samen te stellen over de website-activiteit en om aan ons services aan te bieden die verband houden met het gebruik van de website en internet-gerelateerde diensten.",
        "Het in het kader van Google Analytics door uw browser verzonden IP-adres, zal niet met andere gegevens van Google worden gecombineerd. U kunt het gebruik van cookies weigeren door de juiste instellingen van uw browsersoftware te selecteren. Wij wijzen u er echter op dat u in dat geval niet alle mogelijkheden van deze website kunt benutten. Daarnaast kunt u het verzenden van de door de cookies verzamelde en aan uw gebruik van de website gerelateerde gegevens (incl. uw IP-adres) naar Google en de verwerking van die gegevens door Google voorkomen, door de via deze link beschikbare browser plug-in te downloaden en te installeren.",
      ],
    },
  },
  fr: {
    pageTitle: "Juridique",
    legal: {
      subTitle: "MENTIONS LÉGALES",
      paragraphs: [
        "L'utilisation du site Web est soumise au respect des conditions générales décrites ci-dessous. En naviguant sur le site Web de Vitruvi, vous déclarez avoir pris connaissance de ces conditions générales d'utilisation et les avoir acceptées sans réserve.",
        "Vitruvi accorde une grande attention au développement et à la mise à jour de ce site Web. Cependant, Vitruvi ne peut garantir que les informations sur le site Web sont correctes. Des inexactitudes et des omissions peuvent donc jamais être totalement exclues. De même que la non-mise à jour éventuelle du contenu du site Web doit toujours être prise en compte par le visiteur.",
        "Toutes les informations relatives aux projets sont données à titre indicatif et sous réserve de vente.",
        "L'utilisateur sait que les informations peuvent être modifiées sans préavis et sont toujours fournies sous forme de données brutes, non individualisées. Ces données ne peuvent donc être utilisées qu'à titre d'information générale.",
        "En cas de liens vers d'autres sites Web, Vitruvi ne peut en aucun cas être tenu responsable du contenu et de l'existence de ces autres sites Web.",
        "Vitruvi décline toute responsabilité quant au contenu du site Web et à son utilisation.",
        "L'utilisation du site Web ne confère à l'utilisateur aucun droit de propriété intellectuelle sur le contenu du site Web. Celui-ci reste la propriété exclusive de Vitruvi.",
        "Les informations accessibles sur le site Web, la conception graphique, les textes, dessins, photos, données, logos, marques et autres éléments affichés sur le site Web sont protégés par le droit des marques et/ou le droit d'auteur et/ou d'autres réglementations applicables en matière de propriété intellectuelle.",
        "Il est strictement interdit de copier, adapter, traduire, modifier, publier, diffuser ou modifier tout ou partie du site Web, ou des éléments similaires, sous quelque forme et de quelque manière que ce soit, sauf autorisation préalable et écrite de Vitruvi.",
        "À titre d'exception, et conformément aux dispositions de la loi du 30 juin 1994 relative aux droits d'auteur et aux droits voisins, vous avez le droit de télécharger et de copier les informations de ce site Web pour un usage strictement personnel.",
        "L'utilisation non autorisée de tout ou partie du site Web peut entraîner des poursuites judiciaires conformément aux lois en vigueur. Vitruvi garantit que vos données personnelles ne seront pas collectées, stockées ou manipulées à votre insu lors du téléchargement d'une page de son site Web.",
        "Si de telles données nous sont toutefois communiquées lors d'un contact volontaire de votre part, Vitruvi s'engage à les garder strictement confidentielles, à ne pas les vendre ou les mettre à disposition de tiers de quelque manière que ce soit, et à ne pas divulguer leur existence dans ses registres.",
        "Ces données seront uniquement utilisées pour vous informer des nouveautés et des offres sur notre site Web, si vous avez expressément donné votre consentement.",
        "Vous avez le droit de demander la suppression ou la modification de vos données personnelles enregistrées dans nos registres sur simple demande. Le traitement demandé sera confirmé dans les 5 jours suivant la demande.",
      ],
    },
    policy: {
      subTitle: "POLITIQUE DE CONFIDENTIALITÉ",
      paragraphs: [
        "Lorsque vous achetez un bien immobilier, que vous nous fournissez des produits ou des services, que vous souhaitez recevoir des informations sur Vitruvi ou que vous profitez de nos services, ou lorsque vous visitez notre site Web [www.vitruvi.be] (le « Site Web »), nous recueillerons certaines données personnelles vous concernant (« Données personnelles »).",
        "Cette politique de protection de la vie privée (la « Politique ») vise à vous informer sur la manière dont nous, Vitruvi et l'ensemble de ses filiales (« Vitruvi », « notre » ou « nous ») collectons des données personnelles dans ce cadre, pourquoi nous le faisons, avec quels tiers nous les partageons, pendant combien de temps nous les conservons, quels droits vous avez et comment vous pouvez les exercer.",
        "<strong>1. Qui sommes-nous ?</strong> Vitruvi est responsable du traitement de vos données personnelles. Son siège social est situé à 8760, Meulebeke, Ooigemstraat 47 et elle est enregistrée auprès de la BCE sous le numéro 0726.899.885. Vous pouvez nous contacter via les coordonnées générales suivantes : E-mail : info@Vitruvi.be",
        // Continue with the rest of the privacy policy paragraphs
      ],
    },
    cookies: {
      subTitle: "POLITIQUE DE COOKIES",
      paragraphs: [
        "Le site Web de Vitruvi utilise des cookies. Ce sont de petits fichiers texte créés par un programme sur le serveur de ce site Web, mais stockés sur (le disque dur de) votre ordinateur. Les cookies sont au moins partiellement nécessaires sur le plan technique pour une communication fluide entre votre ordinateur et le site Web. Les cookies peuvent également être utiles (par exemple, pour enregistrer les paramètres de préférence) et certaines données peuvent être transmises à ce site Web pour évaluer et améliorer son fonctionnement. Si vous acceptez le cookie – par les paramètres de votre navigateur – Vitruvi suppose que vous consentez au traitement des données en découlant en continuant à naviguer. Plus d'informations sur les cookies et comment les supprimer, selon le type de navigateur utilisé, peuvent être trouvées sur cookiesandyou.com.",
        "<strong>GOOGLE ANALYTICS</strong>",
        "Le site Web de Vitruvi utilise également Google Analytics, un service d'analyse de site Web fourni par Google Inc. ('Google'). Google Analytics utilise des 'Cookies', des fichiers texte stockés sur les ordinateurs et permettant d'analyser votre utilisation du site Web.",
        "Les données générées par le cookie concernant votre utilisation de ce site Web sont généralement transférées à un serveur de Google aux États-Unis. Elles peuvent y être stockées. L'anonymisation de l'adresse IP est activée, de sorte que votre adresse IP est d'abord raccourcie par Google au sein de l'Union européenne et dans d'autres États contractants de l'Espace économique européen. Ce n'est que dans des cas exceptionnels que l'adresse IP complète est envoyée à un serveur de Google aux États-Unis et y est raccourcie.",
        "Pour notre compte, Google utilise ces données pour évaluer votre utilisation du site Web, compiler des rapports sur l'activité du site Web et nous fournir des services associés à l'utilisation du site Web et d'Internet.",
        "L'adresse IP transmise par votre navigateur dans le cadre de Google Analytics ne sera pas combinée avec d'autres données de Google. Vous pouvez refuser l'utilisation de cookies en sélectionnant les paramètres appropriés de votre logiciel de navigation. Cependant, nous attirons votre attention sur le fait que dans ce cas, vous ne pourrez peut-être pas utiliser toutes les fonctionnalités de ce site Web. En outre, vous pouvez empêcher l'envoi des données générées par les cookies et relatives à votre utilisation du site Web (y compris votre adresse IP) à Google et le traitement de ces données par Google en téléchargeant et en installant le plug-in de navigateur disponible via ce lien.",
      ],
    },
  },
  en: {
    pageTitle: "Legal",
    legal: {
      subTitle: "LEGAL NOTICES",
      paragraphs: [
        "The use of the website is subject to compliance with the general conditions described below. By browsing the Vitruvi website, you declare that you have read these general terms of use and accepted them without reservation.",
        "Vitruvi pays great attention to the development and updating of this website. However, Vitruvi cannot guarantee that the information on the website is correct. Inaccuracies and omissions can therefore never be completely excluded. Just as the possible non-updating of the content of the website must always be kept in mind by the visitor.",
        "All information regarding projects is non-binding and subject to sale.",
        "The user knows that the information may be changed without prior notice and is always provided in the form of raw, non-individualized data. These data can therefore only be used for general information purposes.",
        "In case links to other websites are provided, Vitruvi can in no case be held responsible for the content and existence of those other websites.",
        "Vitruvi disclaims all responsibility with regard to the content of the website and its use.",
        "The use of the website does not grant the user any intellectual property rights with respect to the content of the website. It remains the exclusive property of Vitruvi.",
        "The information accessible on the website, the graphic design, texts, drawings, photos, data, logos, trademarks and other elements displayed on the website are reserved and protected by trademark law and/or copyright law and/or other applicable regulations that may apply to intellectual property.",
        "It is strictly forbidden to copy, adapt, translate, modify, publish, distribute or change all or part of the website, or similar elements, in any form and by any means, without prior written permission from Vitruvi.",
        "By way of exception, and in accordance with the provisions of the law of June 30, 1994 on copyright and neighboring rights, you have the right to download and copy the information on this website for strictly personal use.",
        "Unauthorized use of the entire website or part of it may give rise to legal proceedings in accordance with the applicable laws. Vitruvi guarantees that your personal data will not be collected, stored or manipulated without your knowledge when downloading a page from its website.",
        "If such data is nevertheless brought to our attention in a voluntary contact from you, Vitruvi undertakes to keep it strictly confidential, not to sell it or make it available to third parties in any way, and not to disclose its existence in its records.",
        "This data will only be used to inform you of new products and offers on our website, if you have expressly given your consent.",
        "You have the right to request the deletion or modification of personal data registered in our records upon simple request. The requested processing will be confirmed within 5 days of the request.",
      ],
    },
    policy: {
      subTitle: "PRIVACY POLICY",
      paragraphs: [
        "When you purchase real estate, provide us with products or services, request information about Vitruvi or enjoy our services, or when you visit our website [www.vitruvi.be] (the 'Website'), we will collect certain personal data about you ('Personal Data').",
        "This privacy policy (the 'Policy') aims to inform you about how we, Vitruvi and all of its subsidiaries ('Vitruvi', 'our' or 'we') collect personal data in this context, why we do so, with which third parties we share it, how long we keep it, what rights you have and how you can exercise them.",
        "<strong>1. Who are we?</strong> Vitruvi is the controller of your personal data. Its registered office is located at 8760, Meulebeke, Ooigemstraat 47 and it is registered with the BCE under number 0726.899.885. You can contact us via the following general contact details: E-mail: info@Vitruvi.be",
        // Continue with the rest of the privacy policy paragraphs
      ],
    },
    cookies: {
      subTitle: "COOKIE POLICY",
      paragraphs: [
        "The Vitruvi website uses cookies. These are small text files created by a program on the server of this website but stored on (the hard drive of) your computer. Cookies are at least partly technically necessary for smooth communication between your computer and the website. Cookies can also be useful (e.g. saving preference settings) and certain data can be forwarded to this website to evaluate and improve its operation. If you accept the cookie – through your browser settings – Vitruvi assumes that you consent to the data processing resulting from it by continuing to browse. More information about cookies and how to delete them, depending on the type of browser used, can be found on cookiesandyou.com.",
        "<strong>GOOGLE ANALYTICS</strong>",
        "The Vitruvi website also uses Google Analytics, a web analysis service provided by Google Inc. ('Google'). Google Analytics uses 'Cookies', text files stored on computers and making it possible to analyze your use of the website.",
        "The data generated by the cookie about your use of this website is usually transferred to a Google server in the US. They can be stored there. IP anonymization is enabled, so that your IP address is first shortened by Google within the European Union and other contracting states of the European Economic Area. Only in exceptional cases is the full IP address sent to a Google server in the US and shortened there.",
        "On our behalf, Google uses this data to evaluate your use of the website, compile reports on website activity, and provide us with services related to website and internet use.",
        "The IP address transmitted by your browser in the context of Google Analytics will not be combined with other data from Google. You can refuse the use of cookies by selecting the appropriate settings on your browser software. However, we would like to point out that in this case, you may not be able to use all the features of this website. In addition, you can prevent the data generated by the cookies and related to your use of the website (including your IP address) from being sent to Google and the processing of this data by Google by downloading and installing the browser plug-in available via this link.",
      ],
    },
  },
}

export const cookieAccepterContent = {
  nl: {
    title: 'Wij gebruiken cookies',
    message: 'Cookies helpen ons de beste ervaring op onze website te leveren. Door onze website te gebruiken, gaat u akkoord met het gebruik van cookies.',
    linkText: 'Lees hoe wij cookies gebruiken.',
    acceptButton: 'ACCEPTEREN'
  },
  fr: {
    title: 'Nous utilisons des cookies',
    message: 'Les cookies nous aident à offrir la meilleure expérience sur notre site Web. En utilisant notre site Web, vous acceptez l\'utilisation des cookies.',
    linkText: 'Découvrez comment nous utilisons les cookies.',
    acceptButton: 'ACCEPTER'
  },
  en: {
    title: 'We use cookies',
    message: 'Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies.',
    linkText: 'Find out how we use cookies.',
    acceptButton: 'ACCEPT'
  }
}
