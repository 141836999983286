import React, { useEffect, useState, useRef } from 'react'
import httpClient from '../components/helpers/httpClient.js'
import './HomePage.css'
import brushStroke from '../assets/brush-stroke.png'
import ScrollDownAnimation from '../assets/LottieAnimations/ScrollDownAnimation.js'
import Menu from '../components/Menu.js'
import Footer from '../components/Footer.js'
import { useNavigate } from 'react-router-dom'
import { getLocationPart } from '../components/helpers/helpers.js'
import { IoLocationOutline } from "react-icons/io5"
import { homePageContent } from '../assets/content/content.js'
import { getLanguage } from '../components/helpers/helpers'
import { IoIosArrowRoundDown } from "react-icons/io"

// import { projects } from '../assets/content/projects.js'
import { news } from '../assets/content/news'

function HomePage({ projects }) {

  const [activeIndex, setActiveIndex] = useState({ word: 0, letter: -1 })
  const [isLogoVisible, setIsLogoVisible] = useState(false)
  const [showWelcome, setShowWelcome] = useState(!sessionStorage.getItem('hasVisited'))
  const [isAboutVisible, setIsAboutVisible] = useState(false)
  const [isFeaturedVisible, setIsFeaturedVisible] = useState(false)
  const [isProjectsVisible, setIsProjectsVisible] = useState(false)
  const [isNewsVisibile, setIsNewsVisibile] = useState(false)
  const [selectedProjectType, setSelectedProjectType] = useState('all')
  const [uniqueProjectTypes, setUniqueProjectTypes] = useState([])
  const [menuLogo, setMenuLogo] = useState()

  const slogan = ['connect.', 'respect.', 'create']
  const slogan2 = ['real estate']

  const navigate = useNavigate()
  const selectedLang = getLanguage()
  const content = homePageContent[selectedLang]

  const aboutRef = useRef(null)
  const featuredRef = useRef(null)
  const projectsRef = useRef(null)
  const newsRef = useRef(null)
  const titleWordsTimer = useRef(null)
  const highlightTimer = useRef(null)
  const aboutContentTimer = useRef(null)

  const logoWhite = require('../assets/logo/logo_vitruvi_transparant_wit.png')
  const logoColor = require('../assets/logo/logo_vitruvi_transparant_color.png')

  const featuredProject = projects.find(project => project.is_featured) || null
  const featuredNews = news.find(newsItem => newsItem.is_featured) || news[news.length - 1]

  useEffect(() => {

    const getUniqueTypesByLanguage = (projects, selectedLang) => {
      const typesSet = new Set()
      typesSet.add('all')

      projects.forEach(project => {
        if (project.types[selectedLang]) {
          project.types[selectedLang].forEach(type => typesSet.add(type))
        }
      })


      return Array.from(typesSet)
    }

    projects && setUniqueProjectTypes(getUniqueTypesByLanguage(projects, selectedLang))

  }, [projects])

  useEffect(() => {
    // Check if the user has already visited
    const hasVisited = sessionStorage.getItem('hasVisited')

    document.body.style.overflow = 'hidden'  // Disable scrolling initially
    document.documentElement.style.overflow = 'hidden'

    if (!hasVisited) {
      setShowWelcome(true)
      sessionStorage.setItem('hasVisited', 'true')
    }

    return () => {
      document.body.style.overflow = 'hidden auto' // Ensure scrolling is re-enabled
      document.documentElement.style.overflow = 'hidden auto'
    }
  }, [showWelcome])

  // Section observers

  useEffect(() => {
    const aboutObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setIsAboutVisible(entry.isIntersecting)
      })
    }, { threshold: 0.5 }) // Trigger when 50% of the 'About' section is visible

    const featureObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setIsFeaturedVisible(entry.isIntersecting)
      })
    }, { threshold: 0.5 }) // Adjust threshold as needed

    const projectsObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setIsProjectsVisible(entry.isIntersecting)
      })
    }, { threshold: 0.5 }) // Adjust threshold as needed

    const newsObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setIsNewsVisibile(entry.isIntersecting)
      })
    }, { threshold: 0.3 }) // Adjust threshold as needed

    if (aboutRef.current) {
      aboutObserver.observe(aboutRef.current)
    }

    if (featuredRef.current) {
      featureObserver.observe(featuredRef.current)
    }

    if (projectsRef.current) {
      projectsObserver.observe(projectsRef.current)
    }

    if (newsRef.current) {
      newsObserver.observe(newsRef.current)
    }

    return () => {
      if (aboutRef.current) {
        aboutObserver.unobserve(aboutRef.current)
      }
      if (featuredRef.current) {
        featureObserver.unobserve(featuredRef.current)
      }
      if (projectsRef.current) {
        projectsObserver.unobserve(projectsRef.current)
      }
      if (newsRef.current) {
        newsObserver.unobserve(newsRef.current)
      }
    }
  }, [aboutRef, featuredRef, projectsRef, newsRef])

  // Animation controls

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY
      const viewportHeight = window.innerHeight

      if (currentScroll >= viewportHeight) {
        setIsLogoVisible(true)
      } else if (currentScroll < viewportHeight) {
        setIsLogoVisible(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isLogoVisible])

  useEffect(() => {
    const { word, letter } = activeIndex
    const currentWord = slogan[word]

    if (showWelcome) {

      if (letter < currentWord.length - 1) {
        const timeoutId = setTimeout(() => {
          setActiveIndex({ word, letter: letter + 1 })
        }, 50)
        return () => clearTimeout(timeoutId)
      } else if (word < slogan.length - 1) {
        const timeoutId = setTimeout(() => {
          setActiveIndex({ word: word + 1, letter: 0 })
        }, 400)
        return () => clearTimeout(timeoutId)
      }

      const totalDelay = 50

      setTimeout(() => {
        const welcomePage = document.querySelector('.welcome_page')
        welcomePage.classList.remove('active')
      }, 1200)
    }

    setTimeout(() => {
      const quote = document.querySelector('.quote')
      if (quote) {
        quote.classList.add('active')
      }
    }, 1200)

    document.body.style.overflow = 'hidden auto'
    document.documentElement.style.overflow = 'hidden auto'

  }, [activeIndex, slogan])

  useEffect(() => {
    const aboutSection = aboutRef.current
    const titleWords = aboutSection.querySelectorAll('.section_title__word')
    const highlight = aboutSection.querySelector('.highlight')
    const subContent = aboutSection.querySelector('.section_sub_content')

    // Clear any existing timers when visibility changes
    if (titleWordsTimer.current) {
      clearTimeout(titleWordsTimer.current)
    }
    if (highlightTimer.current) {
      clearTimeout(highlightTimer.current)
    }
    if (aboutContentTimer.current) {
      clearTimeout(highlightTimer.current)
    }

    if (isAboutVisible) {
      highlightTimer.current = setTimeout(() => {
        highlight.classList.add('animated')
      }, 750)

      subContent.classList.add('visible')

      setMenuLogo(logoWhite)

    } else {
      // highlight.classList.remove('animated')
      // subContent.classList.remove('visible')

      setMenuLogo(logoColor)

    }

    // Clear timers on cleanup
    return () => {
      if (titleWordsTimer.current) {
        clearTimeout(titleWordsTimer.current)
      }
      if (highlightTimer.current) {
        clearTimeout(highlightTimer.current)
      }
      if (aboutContentTimer.current) {
        clearTimeout(highlightTimer.current)
      }
    }

  }, [isAboutVisible])

  useEffect(() => {
    const featuredSection = featuredRef.current
    const titleWords = featuredSection.querySelectorAll('.section_title__word')
    const highlight = featuredSection.querySelector('.highlight')
    const subContent = featuredSection.querySelector('.section_sub_content')

    // Clear any existing timers when visibility changes
    if (titleWordsTimer.current) {
      clearTimeout(titleWordsTimer.current)
    }
    if (highlightTimer.current) {
      clearTimeout(highlightTimer.current)
    }
    if (aboutContentTimer.current) {
      clearTimeout(highlightTimer.current)
    }

    if (isFeaturedVisible) {
      highlightTimer.current = setTimeout(() => {
        highlight.classList.add('animated')
      }, 750)

      subContent.classList.add('visible')

      setMenuLogo(logoColor)

    } else {
      // highlight.classList.remove('animated')
      // subContent.classList.remove('visible')
    }

    // Clear timers on cleanup
    return () => {
      if (titleWordsTimer.current) {
        clearTimeout(titleWordsTimer.current)
      }
      if (highlightTimer.current) {
        clearTimeout(highlightTimer.current)
      }
      if (aboutContentTimer.current) {
        clearTimeout(highlightTimer.current)
      }
    }

  }, [isFeaturedVisible])

  useEffect(() => {
    const newsSection = newsRef.current
    const titleWords = newsSection.querySelectorAll('.section_title__word')
    const highlight = newsSection.querySelector('.highlight')
    const subContent = newsSection.querySelector('.section_sub_content')

    // Clear any existing timers when visibility changes
    if (titleWordsTimer.current) {
      clearTimeout(titleWordsTimer.current)
    }
    if (highlightTimer.current) {
      clearTimeout(highlightTimer.current)
    }
    if (aboutContentTimer.current) {
      clearTimeout(highlightTimer.current)
    }

    if (isNewsVisibile) {
      highlightTimer.current = setTimeout(() => {
        highlight.classList.add('animated')
      }, 750)

      subContent.classList.add('visible')
      setMenuLogo(logoColor)

    } else {
      // highlight.classList.remove('animated')
      // subContent.classList.remove('visible')
    }

    // Clear timers on cleanup
    return () => {
      if (titleWordsTimer.current) {
        clearTimeout(titleWordsTimer.current)
      }
      if (highlightTimer.current) {
        clearTimeout(highlightTimer.current)
      }
      if (aboutContentTimer.current) {
        clearTimeout(highlightTimer.current)
      }
    }

  }, [isNewsVisibile])

  useEffect(() => {
    if (isProjectsVisible) {
      setMenuLogo(logoWhite)
    }
  }, [isProjectsVisible])

  const handleLinkClick = (path, hash) => {
    navigate(path, { replace: true })
    setTimeout(() => {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 350)
  }


  const handleHoverProject = (event, type) => {
    const thisElement = event.currentTarget
    const thisStatus = thisElement.querySelector('.project_status')



    if (type == 'enter') {
      thisStatus.classList.add('hovered')
    } else {
      thisStatus.classList.remove('hovered')
    }
  }

  return (
    <div className='home_page'>

      <Menu
        menuLogo={menuLogo}
        isLogoVisible={isLogoVisible}
        setShowWelcome={setShowWelcome}
      />

      <div className={`welcome_page ${showWelcome ? "active" : ""}`}>
        <img className='logo' src='./img/logo_vitruvi_transparant_wit_big.png' alt='Vitruvi Logo' />
        <div className='slogan'>
          {content.slogan.map((word, wordIndex) => (
            <div className='word' key={wordIndex}>
              {Array.from(word).map((letter, letterIndex) => (
                <p className={`letter ${activeIndex.word > wordIndex || (activeIndex.word === wordIndex && activeIndex.letter >= letterIndex) ? 'active' : ''}`}
                  key={letterIndex}>
                  {letter}
                </p>
              ))}
            </div>
          ))}
        </div>
        <div className='slogan sub'>
          <div className='word'>{content.subSlogan}</div>
        </div>
      </div>

      <section id='home'>
        <img className='logo' src='./img/logo_vitruvi_transparant_color.png' alt='Vitruvi Logo' />
        <img className='cover_photo' src='./img/frontpage.jpg' alt='Frontpage' />
        {/* <div className='quote'>
          <p>{content.quote}</p>
          <p>{content.quoteAuthor}</p>
        </div> */}
        <button className='content_button' onClick={() => handleLinkClick('/', '#featured-project')}>{content.homeProjectsButton}<IoIosArrowRoundDown /></button>
        <div className='scroll_down__icon'><ScrollDownAnimation /></div>
      </section>

      <section id='about' ref={aboutRef}>
        <div className='section_content'>
          <h1 className='section_title'>
            <div className={`section_title__word visible`}>{content.aboutTitle[0]}</div>
            <div className={`section_title__word visible highlight`} style={{ backgroundImage: `url(${brushStroke})` }}>{content.aboutTitle[1]}</div>
          </h1>

          <div className='section_sub_content'>
            <h2 className='section_sub_title'>{content.aboutSubTitle}</h2>
            <p className='section_text' dangerouslySetInnerHTML={{ __html: content.aboutParagraph1 }} />
            <p className='section_text' dangerouslySetInnerHTML={{ __html: content.aboutParagraph2 }} />
            <p className='section_text' dangerouslySetInnerHTML={{ __html: content.aboutParagraph3 }} />

          </div>
        </div>
      </section>

      <section className={`${!featuredProject ? "hidden" : ""}`} id={`featured-project`} ref={featuredRef}>
        <div className={`section_content`}>
          <h1 className='section_title'>
            <div className={`section_title__word visible`}>{content.projectTitle[0]}</div>
            <div className={`section_title__word visible highlight`} style={{ backgroundImage: `url(${brushStroke})` }}>{content.projectTitle[1]}</div>
          </h1>

          <div className='section_sub_content' dangerouslySetInnerHTML={{ __html: featuredProject ? featuredProject.description_short[selectedLang] : null }} />

          <div className='buttons'>
            <a href={`/projects/${featuredProject?.id}`}>
              <button className='content_button'>{content.projectButton1}</button>
            </a>
            <button className='content_button' onClick={() => handleLinkClick('/', '#projects')}>{content.projectButton2}</button>
          </div>
        </div>

        <div className='section_image' onClick={() => navigate(`/projects/${featuredProject?.id}`)}>
          <img src={featuredProject?.main_image} alt='Featured Project' />
        </div>
      </section>

      <section className={`${projects.length == 0 ? "hidden" : ""}`} id="projects" ref={projectsRef}>
        <div className='projects_filter_menu'>
          {
            uniqueProjectTypes.map((type, index) => (
              <div className={`${type == selectedProjectType ? 'selected' : ''}`} key={index} onClick={() => setSelectedProjectType(type)}>{type}</div>
            ))
          }
        </div>
        <div className='projects_grid'>
          {
            projects
            .filter(project => (
              (selectedProjectType == 'all') ||
              (project.types[selectedLang].includes(selectedProjectType))
            ))
            .map((project, index) => (
              <div
                className='project_wrapper'
                key={index}
                onMouseEnter={(e) => handleHoverProject(e, 'enter')}
                onMouseLeave={(e) => handleHoverProject(e, 'leave')}
                onClick={() => navigate(`/projects/${project.id}`)}
              >
                <div className='project_status'>
                  {project.status[selectedLang]}
                </div>
                <img src={project.main_image} alt={project.project_name} />
                <div className='project_info'>
                  <div className='project_info_text'>
                    <h3>{project.project_name}</h3>
                    <p><IoLocationOutline />{getLocationPart(project.location)}</p>
                  </div>
                  <button className='content_button'>{content.projectsButton}</button>
                </div>
              </div>
            ))
          }
        </div>
      </section>

      <section className={`${featuredNews ? "" : "hidden"}`} id={`featured-news`} ref={newsRef}>
        <div className='section_content'>
          <h1 className='section_title'>
            <div className={`section_title__word visible`}>{content.newsTitle[0]}</div>
            <div className={`section_title__word  visible highlight`} style={{ backgroundImage: `url(${brushStroke})` }}>{content.newsTitle[1]}</div>
          </h1>

          <div className='section_sub_content'>{featuredNews.content.title[selectedLang]}</div>

          <div className='buttons'>
            <button className='content_button' onClick={() => navigate(`/news/${featuredNews.id}`)}>{content.newsButton1}</button>
            <button className='content_button' onClick={() => navigate(`/news`)}>{content.newsButton2}</button>
          </div>
        </div>

        <div className='section_image' onClick={() => navigate(`/news/${featuredNews.id}`)}>
          <img src={require(`../assets/images/news/${featuredNews.main_image}`)} alt='Featured News' />
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default HomePage